import { Button, Form, Input, Switch } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Link, useActionData, useLoaderData, useSubmit, Outlet } from 'react-router-dom';
import { getEmailTemplate, updateEmailTemplate } from '../../api/settings/email-template';
import GrapesJSEditor from '../../components/inputs/email-editor.jsx';
import Page from '../../components/page/page';

const EmailTemplateDetails = ({ title }) => {
    const { emailTemplate } = useLoaderData();
    const { errors = null } = useActionData() || {};
    const submit = useSubmit()
    const formDataRef = useRef({
        active: emailTemplate.active,
        subject: emailTemplate.subject
    });

    const handleSubjectChange = (event) => {
        formDataRef.current.subject = event.target.value;
    }

    const handleActiveChagne = (checked) => {
        formDataRef.current.active = checked;
    };

    const handleSave = ({ html, css }) => {
        submit({
            ...formDataRef.current,
            content: html,
            css,
            action: 'update'
        }, {
            replace: true,
            method: 'POST',
            encType: 'application/json'
        });
    }

    return (
        <Page title={title}>
            <h2>{emailTemplate.name}</h2>
            <Form layout="vertical" errors={errors}>
                <Form.Item label="Active">
                    <Switch defaultChecked={!!formDataRef.current?.active} onChange={handleActiveChagne} />
                </Form.Item>
                <Form.Item label="Subject">
                    <Input defaultValue={formDataRef.current?.subject} onChange={handleSubjectChange} />
                </Form.Item>
                <Form.Item label="Content">
                    <GrapesJSEditor content={emailTemplate.content} css={emailTemplate.css} sample={emailTemplate.sample} onSave={handleSave} />
                </Form.Item>
                <div className="actions align--left">
                    {/* <Button type="primary" onClick={handleSave}>Save</Button> */}
                    <Link to={-1}>
                        <Button>Cancel</Button>
                    </Link>
                </div>
            </Form>
            <Outlet />
        </Page>
    );
};

EmailTemplateDetails.Actions = {
    update: async ({ params, data }) => {
        const { id, language } = params;
        const response = await updateEmailTemplate(id, language, data);
        if (response.error) {
            return { errors: response.error };
        }
        return false;
    },
};

EmailTemplateDetails.Loader = async ({ params }) => {
    const { id, language } = params;
    const emailTemplate = await getEmailTemplate(id, language);
    return { emailTemplate };
};

EmailTemplateDetails.propTypes = {
    title: PropTypes.string,
};

export default EmailTemplateDetails;
