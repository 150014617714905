import { UserAddOutlined,SearchOutlined } from '@ant-design/icons';
import { Table,Button,Space,Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { vatListColumns } from '../../library/constants/tableColumns/vatListColumns.js';
import { getVatList } from '../../api/settings/vatType.js';
import { searchParamsToObject } from '../../library/helpers/index.js';
import { useFetch } from '../../hooks';

function VATList(props) {
  const { title } = props;
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [list, hasMore, isLoading, , {
		setSort,
		setFilters,
		loadMore
	}] = useFetch({ initialData })

  return (
    <Page  title={title} header={(
      <>
      <div></div>
         <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
         <Input.Search placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
        </Space.Compact>
        <Button type="primary" onClick={() => { navigate(generateRouteUrl('VATCreate')) }} icon={<UserAddOutlined />}>Add VAT Type</Button>
      </>
    )} >
     
      <Table
        columns={vatListColumns}
				dataSource={list}
        rowKey="id"
        onRow={data => ({
          onClick: () => {
            navigate(generateRouteUrl('VATDetails', {
              userId: data.id
            }))
          }
        })}
        isLoading={isLoading}
        onChange={setSort}
        hasMore={hasMore}
        loadMore={loadMore}
        sticky
      />
      <Outlet />
    </Page>
  )
}

VATList.Loader = async ({request}) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getVatList({ filter, page, ...sort })
  return { data, meta }
}

VATList.propTypes = {
  title: PropTypes.string,
}

export default VATList
