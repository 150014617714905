import { Button, Space } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { supportedLanguages } from '../../helpers/language.js';
import { generateRouteUrl } from '../routes.js';

export const TemplateListColumns = [
    {
        title: 'Edit',
        key: 'edit',
        render: (_, record) => (
            <Space size="small" wrap>
                {supportedLanguages.map(language => (
                    <Link key={language} to={generateRouteUrl('EmailTemplateDetails', {
                        id: record.id,
                        language
                    })}><Button size="small">{language}</Button></Link>
                ))}
            </Space>
        ),
    },
    {
        title: 'Hisroy',
        dataIndex: 'id',
        render: id => <Link to={generateRouteUrl('EmailHistory', {
            id
        })}><Button>History</Button></Link>
    },
    {
        title: 'Status',
        dataIndex: 'active',
        render: active => active ? 'Active' : 'Not Active'
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
    },
    {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
    },
    {
        title: 'updatedBy',
        dataIndex: 'Updated By',
    },

]