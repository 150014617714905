import { Select, Spin } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { searchVendorSentProjectItems } from '../../api/finance/purchase-bill.js';

function PurcahseBillProjectItemSelect({ filters = {}, value, onChange, ...otherProps }) {
    const debounceTimeout = 200
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    useEffect(() => {
        debounceFetcher()
    }, [])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchVendorSentProjectItems(value ?? '', filters).then(data => {
                const _items = data.map(({ id, name, project_id }) => ({
                    value: id,
                    label: `#${id} - ${name}`,
                    project_id
                }))
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(_items)
                setFetching(false);
            })
        };
        return debounce(loadOptions, debounceTimeout);
    }, [filters, searchVendorSentProjectItems, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            onChange={onChange}
            value={value}
            placeholder="Select Project"
            showSearch
            allowClear
            {...otherProps}
        />
    );
}

PurcahseBillProjectItemSelect.propTypes = {
    filters: PropTypes.object,
    value: PropTypes.arrayOf(PropTypes.number),
    onChange: PropTypes.func,
    currentSelection: PropTypes.object
    // currentSelection: PropTypes.shape({
    //     id: PropTypes.number.isRequired,
    //     company: PropTypes.string.isRequired
    // })
}

export default PurcahseBillProjectItemSelect;