export const VendorQuotationStatuses = {
    1: 'Active',
    2: 'Sent',
    3: 'Visual Requested',
    4: 'Visual Sent',
    5: 'cancel',
}

export const VendorQuotationStatusText = {
    1: 'Quotation request sent',
    2: 'Vendor Sent Quotation',
    3: 'Vendor Requested Visual',
    4: 'Visual Sent to Vendor',
    5: 'Quotation Request Cancelled',
}