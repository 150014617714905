import { axiosInstance } from '../common';
import { generatePath } from 'react-router-dom';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/settings/general-ledger';
const ENDPOINT_SINGLE = '/settings/general-ledger/:Id';


export const getLedgers = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getLedgerDetails = (Id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        Id
    })).then(({ data }) => data)
}
export const updateLedger = (Id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        Id,
    }), payload)
}

export const CreateLedger= (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}