import grapesjs from 'grapesjs';
import pluginNewsletter from 'grapesjs-preset-newsletter';
import 'grapesjs/dist/css/grapes.min.css';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { EmailTemplateEditorCSS, EmailTemplateFooter, EmailTemplateHeader } from '../../library/constants/email-template.js';
import GrapeJsMainTable from '../../library/grapesjs-main-table.js';

grapesjs.plugins.add('gjs-plugin-show-json', (editor, options) => {
  editor.Panels.addButton('options', {
    id: 'show-json',
    className: 'fa fa-clipboard',
    command: 'show-json',
    attributes: {
      title: 'Show JSON',
    },
  });

  editor.Commands.add('show-json', {
    run(editor) {
      const json = JSON.parse(options?.sample);
      const formattedJson = JSON.stringify(json, null, 2);
      editor.Modal.open({
        title: 'Placeholders available',
        content: `<pre>${formattedJson}</pre>`,
      });
      // alert(JSON.stringify(json, null, 2)); // Display JSON in an alert

    },
  });
});

grapesjs.plugins.add('grapesjs-header-footer', (editor, opts = {}) => {
  const options = {
    headerHtml: `
        <div id="static-header" style="background-color:#f6f6f6; padding: 30px; text-align: center;">
          <img src="https://via.placeholder.com/150" alt="logo" width="150" height="50" />
        </div>
      `,
    footerHtml: `
        <div id="static-footer" style="background-color:#f6f6f6; padding: 30px; text-align: center;">
          <p>&copy; 2023 Company. All rights reserved.</p>
        </div>
      `,
    ...opts,
  };

  editor.on('load', () => {
    const canvas = editor.Canvas.getElement();
    const iframe = canvas.querySelector('iframe');
    const iframeDoc = iframe?.contentDocument;

    const editorCss = ` [data-gjs-type="wrapper"] {
        min-height: 100px!important
    }
    .main-container{
      min-height: 100px!important
    }`
    editor.addStyle(editorCss)


    // Add header if not already present
    if (!iframeDoc.querySelector('#static-header')) {
      const header = document.createElement('div');
      header.innerHTML = options.headerHtml;
      iframeDoc.body.insertBefore(header, iframeDoc.body.firstChild);
    }

    // Add footer if not already present
    if (!iframeDoc.querySelector('#static-footer')) {
      const footer = document.createElement('div');
      footer.innerHTML = options.footerHtml;
      iframeDoc.body.appendChild(footer);
    }

    const style = document.createElement('style');
    style.innerHTML = EmailTemplateEditorCSS
    iframeDoc.head.appendChild(style);
  });
})

grapesjs.plugins.add('gjs-plugin-heading', (editor) => {
  editor.BlockManager.add('heading', {
    label: 'Heading',
    content: {
      type: 'heading',
      content: 'Heading',
    },
    attributes: { class: 'fa fa-header' },
  });

  editor.DomComponents.addType('heading', {
    isComponent: el => ['H1', 'H2', 'H3', 'H4', 'H5', 'H6'].includes(el.tagName),
    extend: 'text',
    model: {
      defaults: {
        tagName: 'h1',
        droppable: false,
        editable: true,
        traits: [
          {
            type: 'select',
            label: 'Heading Type',
            name: 'tagName',
            options: [
              { value: 'h1', name: 'H1' },
              { value: 'h2', name: 'H2' },
              { value: 'h3', name: 'H3' },
              { value: 'h4', name: 'H4' },
              { value: 'h5', name: 'H5' },
              { value: 'h6', name: 'H6' },
            ],
            changeProp: 1
          },
        ],
      },
      init() {
        this.listenTo(this, 'change:tagName', this.handleTagChange);
      },
      handleTagChange() {
        const newTagName = this.get('tagName');
        const content = this.view.el.innerHTML; // Preserve the current content

        // Create new element with the updated tag name
        const newEl = document.createElement(newTagName);
        newEl.innerHTML = content;

        // Replace the current element with the new one
        this.view.el.parentNode.replaceChild(newEl, this.view.el);

        // Update the view and model with the new element and content
        this.view.setElement(newEl);
        this.set({ tagName: newTagName });
        this.trigger('change:content', this); // Trigger content change

        // Re-render the view to reflect the changes
        this.view.render();
      },
    },
    view: {
      onRender() {
        const el = this.el;
        el.contentEditable = true
        const tagName = this.model.get('tagName');
        if (el.tagName.toLowerCase() !== tagName) {
          const newEl = document.createElement(tagName);
          newEl.innerHTML = el.innerHTML;
          el.parentNode.replaceChild(newEl, el);
          this.setElement(newEl);
        }
      }
    }
  });
});

const GrapesJSEditor = ({ content, css, sample, onSave }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editor = grapesjs.init({
      container: editorRef.current,
      plugins: [pluginNewsletter, GrapeJsMainTable, 'grapesjs-header-footer', 'gjs-plugin-heading', 'gjs-plugin-show-json'],
      pluginsOpts: {
        'grapesjs-preset-newsletter': {
          // options for the newsletter preset
        },
        'grapesjs-header-footer': {
          headerHtml: EmailTemplateHeader,
          footerHtml: EmailTemplateFooter,
        },
        'gjs-plugin-show-json': {
          sample
        },
        'grapesjsCustomTable': {}
      },
      canvas: {
        styles: [
          'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap',
        ]
      },
      fromElement: true,
      styleManager: { clearProperties: true },
      // Setting global width
      minHeight: '50vh',
      width: '100%',
      storageManager: { type: 0 },
    });

    // Load the initial content into the editor
    editor.setComponents(content);
    editor.setStyle(css);

    // Save handler
    const handleSave = () => {
      const html = editor.getHtml();
      const css = editor.getCss();
      onSave({ html, css });
    };

    // Set up a button for saving the content
    editor.Panels.addButton('options', {
      id: 'save-button',
      className: 'fa fa-floppy-o',
      command: 'save-content',
      attributes: { title: 'Save' },
    });

    editor.Commands.add('save-content', {
      run: handleSave,
    });

    // Clean up on unmount
    return () => {
      editor.destroy();
    };
  }, [content, onSave]);

  return <div ref={editorRef} />;
}

GrapesJSEditor.propTypes = {
  content: PropTypes.string,
  css: PropTypes.string,
  sample: PropTypes.string,
  onSave: PropTypes.func
}

export default GrapesJSEditor;
