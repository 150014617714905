import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '../../design/box.js';
import { DocumentsDownloader } from '../../form/index.js';

const FilePropType = PropTypes.shape({
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    comment: PropTypes.string,
    uploaded_by: PropTypes.string,
    uploaded_time: PropTypes.number,
})

const OrderDocumentSection = ({ projectId, title, files }) => {
    if (!files || files.length === 0) {
        return <></>
    }
    return (
        <>
            <h3>{title}</h3>
            {files && files.map(file => (
                <Box key={file.url} type={Box.BoxTypes.GRAY}>
                    <DocumentsDownloader key={file.url} image={file?.url} name={file?.name} comment={file?.comment} project_id={projectId} />
                </Box>
            ))}
            <hr />
        </>
    )
}
OrderDocumentSection.propTypes = {
    projectId: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    files: PropTypes.arrayOf(FilePropType),
}

const OrderDocuments = ({ projectId, documents }) => {
    return (
        <>
            {!!documents.supplier_files && documents.supplier_files.length > 0 && <OrderDocumentSection title='Files Sent to Vendor' projectId={projectId} files={documents.supplier_files} />}
            <OrderDocumentSection title='Vendor template' projectId={projectId} files={documents.vendor_template} />
            <OrderDocumentSection title='Template Sent to Studio' projectId={projectId} files={documents.order_template_to_studio} />
            <OrderDocumentSection title='Template Recived from Studio' projectId={projectId} files={documents.studio_template} />
            <OrderDocumentSection title='Template Sent to Customer' projectId={projectId} files={documents.order_template_to_client} />
            <OrderDocumentSection title='Artwork Create Request to Studio' projectId={projectId} files={documents.make_artwork_send_to_studio} />
            <OrderDocumentSection title='Artwork Created by Studio' projectId={projectId} files={documents.make_artwork_from_studio} />
            <OrderDocumentSection title='Created artwork Sent to Customer' projectId={projectId} files={documents.make_artwork_send_to_client} />
            <OrderDocumentSection title='Customer Artwork' projectId={projectId} files={documents.client_artwork} />
            <OrderDocumentSection title='Artwork sent to Studio' projectId={projectId} files={documents.order_artwork_to_studio} />
            <OrderDocumentSection title='Artwork Recieved from Studio' projectId={projectId} files={documents.studio_artwork} />
            <OrderDocumentSection title='Artwork Sent to Vendor' projectId={projectId} files={documents.order_artwork_to_vendor} />
            <OrderDocumentSection title='Proof Recieved from Vendor' projectId={projectId} files={documents.vendor_proof} />
            <OrderDocumentSection title='Proof Sent to Studio' projectId={projectId} files={documents.order_proof_to_studio} />
            <OrderDocumentSection title='Proof Recieved from Studio' projectId={projectId} files={documents.studio_proof} />
            <OrderDocumentSection title='Proof Sent to Customer' projectId={projectId} files={documents.order_proof_to_client} />
        </>
    )
}

OrderDocuments.propTypes = {
    projectId: PropTypes.number.isRequired,
    documents: PropTypes.shape({
        supplier_files: PropTypes.arrayOf(FilePropType),
        vendor_template: PropTypes.arrayOf(FilePropType),
        order_template_to_studio: PropTypes.arrayOf(FilePropType),
        studio_template: PropTypes.arrayOf(FilePropType),
        order_template_to_client: PropTypes.arrayOf(FilePropType),
        make_artwork_send_to_studio: PropTypes.arrayOf(FilePropType),
        make_artwork_from_studio: PropTypes.arrayOf(FilePropType),
        make_artwork_send_to_client: PropTypes.arrayOf(FilePropType),
        client_artwork: PropTypes.arrayOf(FilePropType),
        order_artwork_to_studio: PropTypes.arrayOf(FilePropType),
        studio_artwork: PropTypes.arrayOf(FilePropType),
        order_artwork_to_vendor: PropTypes.arrayOf(FilePropType),
        vendor_proof: PropTypes.arrayOf(FilePropType),
        order_proof_to_studio: PropTypes.arrayOf(FilePropType),
        studio_proof: PropTypes.arrayOf(FilePropType),
        order_proof_to_client: PropTypes.arrayOf(FilePropType),
    })
}

export { OrderDocuments };
