import { Alert, Button } from 'antd';
import React from "react";
import { Link, useRouteLoaderData } from 'react-router-dom';
import { generateRouteUrl } from '../../../library/constants';
import style from './proof-rejected.module.scss';


const ProofRejectedByCustomer = () => {
    const { orderDetails, commentData } = useRouteLoaderData('OrderManagerDetailsPage')
    return (
        <div className={style.container}>
            <h3>Task for you</h3>
            <Alert message=" Proof has been rejected." description={
                !!(commentData?.[3]?.[3]) && (
                    <>
                        <strong>Client Comment : </strong>
                        {commentData?.[3]?.[3]}
                    </>
                )}
                type="error"
                action={
                    <Link to={generateRouteUrl('CustomerArtworkToVendor', {
                        itemId: orderDetails.id,
                    })}>
                        <Button className={style.greenButton}>Re - Request Proof</Button>
                    </Link>

                } />
        </div>
    )
}


export default ProofRejectedByCustomer;