import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Entities from '../../library/dynamics/entities';
const { Option } = Select

function ExecutiveEntitySelect({ value = null, onChange = () => { }, name, ...otherProps }) {
    const [val, setVal] = useState(value)

    return (
        <>
            <Select
                optionFilterProp="label"
                onChange={(val) => {
                    setVal(val)
                    if (onChange) {
                        onChange(val, Entities.find(({ id }) => id === val))
                    }
                }}
                value={value}
                {...otherProps}
            >
                {Entities.map((entity) => (
                    <Option key={entity.id} value={entity.id} label={entity.name} >
                        {entity.name}
                    </Option>
                ))}
            </Select>
            {(!!name) && <input type="hidden" name={name} value={val} />}
        </>
    );
}

ExecutiveEntitySelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    // useHidden: PropTypes.bool,
    name: PropTypes.string
}

export default ExecutiveEntitySelect;