import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import { getAllVendors } from '../../api/vendor/vendor';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { VendorColumns, generateRouteUrl } from '../../library/constants/index.js';
import { searchParamsToObject } from '../../library/helpers/index.js';
// import { VendorDetails } from '../../components/form';



function VendorListView(props) {
	const initialData = useLoaderData()
	const navigate = useNavigate()
	//const [filtersVisible, setFiltersVisible] = useState(false)
	const [list, hasMore, isLoading, , {
		setSort,
		setFilters,
		loadMore
	}] = useFetch({ initialData })

	const handleRowClick = (_, record) => {
		navigate(generatePath('/vendors/:id', {
			id: record.id
		}))
	}

	return (
		<Page className='vendor-list' backLink={false} title={props.title} summary={initialData?.meta?.totalCount ? `${initialData?.meta?.totalCount} Records` : 'No Records'} header={
			<>
				<div className=""></div>
				<Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
					<Input.Search placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
				</Space.Compact>
				<Button type="primary" onClick={() => { navigate(generateRouteUrl('VendorCreate')) }} icon={<PlusOutlined />}>Add new Vendor</Button>
			</>
		}>
			<Table
				columns={VendorColumns}
				data={list}
				/* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
				isLoading={isLoading}
				onChange={setSort}
				onRowClick={handleRowClick}
				hasMore={hasMore}
				loadMore={loadMore}
			/>
		</Page>
	)
}


VendorListView.Loader = async ({ request }) => {
	const url = new URL(request.url)
	const { filter, page, sort } = searchParamsToObject(url.searchParams)
	const { data, meta } = await getAllVendors({ filter, page, ...sort })
	return { data, meta }
}

VendorListView.propTypes = {
	title: PropTypes.string,
}

export default VendorListView
