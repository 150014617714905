import { DeleteOutlined, EditOutlined, InboxOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, generatePath, redirect, useFetcher, useLoaderData, useParams } from 'react-router-dom';
import { getCustomerDetails } from '../../api/customer/customer-details.js';
import { getProductDetails } from '../../api/product/product.js';
import { getClientQuotationPreview, getProjectDetails, sendProjectClientQuotation } from '../../api/project';
import { getProjectItem } from '../../api/project-item';
import { ProjectItemVendorPrices } from '../../components/data/index.js';
import { PdfPreview } from '../../components/data/pdfpreview.jsx';
import projectItemStyles from '../../components/data/project-item/item-overview.module.scss';
import '../../components/data/project-item/item-overview.scss';
import Confirm from '../../components/message/Confirm.jsx';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { formatDate } from '../../library/utilities/intl.js';
const { Panel } = Collapse


const ProjectItemOverviewSelectable = ({ children, data, includeVat, onEdit, onDelete, onVendorChange }) => {
	const itemData = useMemo(() => {
		const res = { ...data }
		res.selected_vendors = res.selected_vendors.map(v => ({
			...v,
			['prices']: data.vendors_prices[v.id]
		}))
		return res
	}, [data])
	const [selectedVendorId, setSelectedVendorId] = useState(itemData.selected_vendors.length == 1 ? itemData.selected_vendors[0]?.id : null)

	return (
		<div className={`project-item-overview ${projectItemStyles.wrapper}`}>
			<div className={projectItemStyles.header}>
				<span className="project-item-index" onClick={onEdit ?? undefined}>
					{data.id}
				</span>
				<span className="project-item-name" onClick={onEdit ?? undefined}>
					{itemData.product.name}
				</span>
				<span className="project-item-actions">
					<span className="project-item-actions--action project-item-date">
						{formatDate(itemData.createdAt)}
					</span>
					{typeof onDelete !== 'undefined' && <span className="project-item-actions--action project-item-delete" onClick={onDelete}>
						<DeleteOutlined />
					</span>}
					<Link to={generateRouteUrl('ProjectItemUpdate', { projectId: data.project_id, itemId: data.id })}>
						<Button icon={<EditOutlined />} shape="square" type="primary" ghost />
					</Link>
				</span>
			</div>
			<div className={projectItemStyles.body}>
				{itemData.selected_vendors.map(vendor => {
					return <ProjectItemVendorPrices key={vendor.id} data={{ ...vendor, ['isSelected']: itemData.selected_vendors.length == 1 ? true : selectedVendorId === vendor.id }} includeVat={includeVat} onVendorChange={(vendorId) => {
						setSelectedVendorId(vendorId)
						onVendorChange(data.id, vendorId)
					}} />
				}
				)}
			</div>
			{children}
		</div>
	)
}

ProjectItemOverviewSelectable.propTypes = {
	children: PropTypes.node,
	data: PropTypes.shape({
		id: PropTypes.number.isRequired,
		project_id: PropTypes.number.isRequired,
		selected_vendors: PropTypes.array,
		vendors_prices: PropTypes.object,
		quantities: PropTypes.array,
		product: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		order_status: PropTypes.number,
	}),
	includeVat: PropTypes.bool,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	onVendorChange: PropTypes.func,
}

const SendClientQuotation = ({ title }) => {
	const { items, projectDetails } = useLoaderData()
	const { projectId } = useParams()
	const [selectedItemVendors, setSelectedItemVendors] = useState({})

	useEffect(() => {
		//Select vendor automatically if only single vendor in item
		items.forEach(item => {
			if (item.selected_vendors.length === 1) {
				setSelectedItemVendors(curr => ({
					...curr,
					[item.id]: item.selected_vendors[0]?.id
				}))
			}
		})
	}, [items])

	function hasSingleVendor(item) {
		return item.selected_vendors.length == 1
	}
	let initialShowPreview = items.every(hasSingleVendor)

	const [showPreview, setShowPreview] = useState(initialShowPreview)
	const fetcher = useFetcher()

	const areAllItemsSelected = () => (items.length === (Object.values(selectedItemVendors).length))

	return (
		<Page loading={fetcher.state !== 'idle'} className='projects-send-client-quotation' title={title} >
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><InboxOutlined /> Item Details</>} key="1">
						{items.map(item => {
							return <ProjectItemOverviewSelectable
								key={item.id}
								data={item}
								includeVat={projectDetails.include_vat}
								onVendorChange={(_itemId, _vendorId) => {
									setSelectedItemVendors(curr => ({
										...curr,
										[_itemId]: _vendorId
									}))
								}}
							/>
						})}
						<div className="ant-collapse-footer">
							{areAllItemsSelected() ? (
								<Button
									onClick={() => { setShowPreview(true) }}
									type="primary"
									disabled={!Object.values(selectedItemVendors).length}
									ghost
								>Send to Client</Button>
							) : (
								<Confirm
									content='All items are not selected. Do you wish to proceed ?'
									onConfirm={() => setShowPreview(true)}
									type="primary"
									disabled={!Object.values(selectedItemVendors).length}
									ghost
								>Send to Client</Confirm>

							)}
						</div>
					</Panel>
				</Collapse>
			</div>
			<Drawer width={768} open={showPreview} onClose={() => setShowPreview(false)} destroyOnClose>
				{/* <ClientQuotationPreview
					customerDetails={customerDetails}
					projectDetails={projectDetails}
					items={items}
					selectedVendors={
						selectedItemVendors
					}
					style={{ opacity: fetcher.state !== 'idle' ? 0.6 : 1 }}
				/> */}
				<PdfPreview url={getClientQuotationPreview(projectId, selectedItemVendors)} />
				<hr />
				<div className='actions align--right'>
					<Button type='primary' icon={<EditOutlined />} onClick={() => {
						// fetcher.submit(
						// 	{
						// 		data: selectedItemVendors,
						// 		action: 'editQuote'
						// 	},
						// 	{ method: "post", encType: "application/json" }

						// )
						setShowPreview(false)
					}} ghost>Edit</Button>
					<Button type='primary' icon={<SendOutlined />} onClick={() => {
						/* 
						 * Todo: Add validation
						 */
						fetcher.submit({ items: selectedItemVendors, action: 'sendQuote' }, { method: "post", encType: "application/json" })
					}} loading={fetcher.state !== 'idle'}>Send</Button>
				</div>
			</Drawer>
		</Page >
	)
}

SendClientQuotation.propTypes = {
	title: PropTypes.string
}

SendClientQuotation.Actions = {
	sendQuote: async ({ params, data }) => {
		const { projectId } = params
		try {
			// await deleteProjectClientQuotation(projectId)
			const msg = await sendProjectClientQuotation(projectId, data.items)
			message.success(msg)
			return redirect(generatePath('/projects/:id', {
				id: projectId
			}))

		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},

	// editQuote: async ({ params, data }) => {
	// 	const { projectId } = params
	// 	const itemId = Object.keys(data.data);
	// 	try {

	// 		return redirect(generateRouteUrl('ProjectItemUpdate', {
	// 			projectId,
	// 			itemId: itemId[0]
	// 		}))
	// 	}
	// 	catch (error) {
	// 		message.error(error.message)
	// 	}
	// 	return false
	// }

}

SendClientQuotation.Loader = async ({ params }) => {
	const { projectId = undefined } = params
	if (isNaN(projectId)) {
		throw new Error('Invalid Project ID')
	}
	const projectDetails = await getProjectDetails(projectId)
	if (!projectDetails) {
		throw new Error('Invalid Project')
	}

	const items = await Promise.all(projectDetails.items.map(async ({ id }) => {
		return await getProjectItem(id).then(async (item) => ({
			...item,
			product: await getProductDetails(item.product_id)
		}))
	}))

	const customerDetails = await getCustomerDetails(projectDetails.customer_id)

	projectDetails.primary_contact = projectDetails.contacts.find(c => c.id === projectDetails.primary_contact_id)
	projectDetails.primary_address = customerDetails.addresses.find(a => a.id === projectDetails.primary_address_id)

	return { projectId, projectDetails, items, customerDetails }
}

export default SendClientQuotation