import { Alert ,Button} from 'antd';
import React from "react";
import style from './artwork-sent.module.scss';
import { generateRouteUrl } from '../../../library/constants';
import { Link, useRouteLoaderData } from 'react-router-dom';

const MakeArtworkRejected = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')

    return <div className={style.container}>
        <h3>Task for you</h3>
        {<Alert message="Made Artowrk has been rejected by the client" type="error"  action={<Link to={generateRouteUrl('MakeCustomerArtwork', {
                    itemId: orderDetails.id,
                })}>
                    <Button className={style.greenButton}>Re-Make Artwork</Button>
                </Link>}/>}
    </div>
}

export default MakeArtworkRejected;