// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/order/item';
const ENDPOINT_GENERAL_ACTION = '/order/item/:action';
const ENDPOINT_SINGLE = '/order/item/:id';
const ENDPOINT_SINGLE_ACTION = '/order/item/:id/:action';
const ENDPOINT_SINGLE_ITEM_SPECIAL_ACTION = '/order/item/:itemId/:action/:id/:subAction';

export const getAllOrders = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getOrdersKanabanCount = (payload) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL_ACTION, {
        action: 'kanban-count'
    }), { params: payload }).then(({ data }) => data)
}

export const getOrderDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id' + '/details', {
        id
    })).then(({ data }) => data)
}

export const updateOrderItem = (itemId, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id: itemId
    }), payload).then(({ message }) => message)
}

export const getActivityLogs = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id' + '/activity-log', {
        id
    })).then(({ data }) => data)
}

export const getOrderDocuments = (itemId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'document-details',
    })).then(({ data }) => data)
}

export const fetchTemplateDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id' + '/document-details', {
        id
    })).then(({ data }) => data)
}

export const fetchArtworkDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id' + '/document-details', {
        id
    })).then(({ data }) => data)
}

export const fetchProofDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id' + '/document-details', {
        id
    })).then(({ data }) => data)
}

export const sendOrderToVendor = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/order-send', {
        id
    }), payload).then(({ message }) => message)
}
export const requestVendorDispatchDate = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/notify-vendor-to-ask-expected-date', {
        id
    }), payload).then(({ message }) => message)
}

export const clientDeliveryComplete = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/client-delivery-complete', {
        id
    }), payload)
}
export const inProduction = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/in-production', {
        id
    }), payload)
}
export const onHold = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/on-hold', {
        id
    }), payload).then(({ message }) => message)
}

export const resumeOrder = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_GENERAL + '/:id' + '/on-hold', {
        id
    }), payload).then(({ message }) => message)
}

export const getComment = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL + '/:id' + '/status-comment', {
        id
    })).then(({ data }) => data)
}
export const sendArtworktoVendor = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/artwork-vendor', {
        id
    }), payload)
}


export const sendProoftoClient = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-proof-to-client', {
        id
    }), payload)
}


export const waitingForFiles = (id) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/waiting-for-files', {
        id
    }))
}

export const templateRequest = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/template-request-to-vendor', {
        id
    }), payload)
}

export const proofRequested = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/proof-requested', {
        id
    }), payload)
}

export const rejectArtwork = (id, reason) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'artwork-rejected'
    }), {
        reason
    })
}


export const sendTemplatetoStudio = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-template-to-studio', {
        id
    }), payload)
}

export const sendClientArtworktoStudio = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-client-artwork-to-studio', {
        id
    }), payload)
}

export const sendVendorArtworktoStudio = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-proof-to-studio', {
        id
    }), payload)
}


export const sendOrderShippingDetails = (itemId, id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:itemId/shipping/:id', {
        itemId,
        id
    }), payload).then(({ message }) => message)
}


// export const sendOrderDispatchDate = (itemId, id, payload) => {
//     return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:itemId/expected-dispatch-date/:id', {
//         itemId,
//         id
//     }), payload)
// }

export const updateOrderExpectedDispatchDates = (itemId, data) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:itemId/expected-dispatch-date', {
        itemId,
    }), { data }).then(({ message }) => message)
}

export const confirmOrderDelivery = (itemId, id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:itemId/shipping/:id/confirm', {
        itemId,
        id
    }), payload)
}

export const changeStatus = (id, status_id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id/change-status/:status_id', {
        id, status_id
    }), payload)
}

export const sendTemplatetoCustomer = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL + '/:id' + '/send-template-to-client', {
        id
    }), payload)
}

export const addItemVendorRating = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'rating'
    }), payload).then(({ message }) => message)
}

export const getItemVendorRating = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'rating'
    })).then(({ data }) => data)
}


export const makeArtworkSendToStudio = (itemId, images, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'make-artwork-send-to-studio'
    }), {
        images,
        comment
    }).then(({ message }) => message)
}

export const makeArtworkSendToClient = (itemId, existingImages, newImages = [], comment = '') => {
    const payload = {
        images: existingImages ?? [],
        image_ids: newImages ?? [],
        comment
    }
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'make-artwork-send-to-client'
    }), payload).then(({ message }) => message)
}

export const wishToCancel = (itemId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'cancel-request'
    }), {
        comment
    }).then(({ message }) => message)
}

export const getStatusHistory = (itemId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'status-history'
    })).then(({ data }) => data)
}


export const approveSuggestion = (itemId, suggestionId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ITEM_SPECIAL_ACTION, {
        itemId,
        action: 'suggestion',
        id: suggestionId,
        subAction: 'approved'
    }), {
        comment
    }).then(({ message }) => message)
}

export const declineSuggestion = (itemId, suggestionId, comment) => {
    console.log({ itemId, suggestionId })
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ITEM_SPECIAL_ACTION, {
        itemId,
        action: 'suggestion',
        id: suggestionId,
        subAction: 'canceled'
    }), {
        comment
    }).then(({ message }) => message)
}

export const clientAddressReminder = (id) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'customer-address-reminder'
    })).then(({ data }) => data)
}

export const enableClientRating = (itemId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'customer-rating-link',
    })).then(({ message }) => message)
}

export const disableClientRating = (itemId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE_ACTION, {
        id: itemId,
        action: 'customer-rating-link',
    })).then(({ message }) => message)
}