import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { formatter, parser } from './Currency.jsx';

const CurrencyConversion = ({ value = null, currency, secondCurrency, conversionRate, onChange, precision = 2, ...otherProps }) => {
    const getPrefix = (_currency) => {
        switch (_currency) {
            case 'EUR':
                return '€'

            case 'GBP':
                return '£'

            case 'USD':
                return '$'

            default:
                return ''
        }
    }

    const convertedValue = (v) => v ? v / conversionRate : null

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
            <InputNumber
                value={value}
                controls={false}
                prefix={getPrefix(currency)}
                // decimalSeparator={currency === 'EUR' ? ',' : '.'}
                placeholder={currency === 'EUR' ? '0,00' : '0.00'}
                min={0}
                max={'10000000000'}
                precision={precision}
                formatter={(v) => formatter(v, currency, precision)}
                parser={(v) => parser(v, currency)}
                onFocus={event => event.target.select()}
                style={{ width: '90%', maxWidth: '250px' }}
                disabled
            />
            <InputNumber
                value={convertedValue(value)}
                controls={false}
                prefix={getPrefix(secondCurrency)}
                onChange={(v) => {
                    onChange?.(v * conversionRate)
                }}
                // decimalSeparator={secondCurrency === 'EUR' ? ',' : '.'}
                placeholder={secondCurrency === 'EUR' ? '0,00' : '0.00'}
                formatter={(v) => formatter(v, secondCurrency, precision)}
                parser={(v) => parser(v, secondCurrency)}
                min={0}
                max={'10000000000'}
                precision={precision}
                onFocus={event => event.target.select()}
                onKeyDown={(e) => {
                    const allowedKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', ',', '.'];
                    const functionalKeys = [
                        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete',
                        'Tab', 'Escape', 'Enter', 'Shift', 'Control', 'Alt', 'Backspace'
                    ];

                    if (allowedKeys.includes(e.key) || functionalKeys.includes(e.key) || (e.ctrlKey || e.metaKey)) {
                        return;
                    }
                    e.preventDefault();
                }}
                style={{ width: '90%', maxWidth: '250px' }}
                {...otherProps}
            />
        </div>
    )
}

CurrencyConversion.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func,
    currency: PropTypes.oneOf(['EUR', 'USD', 'GBP']).isRequired,
    secondCurrency: PropTypes.oneOf(['EUR', 'USD', 'GBP']).isRequired,
    conversionRate: PropTypes.number.isRequired,
    precision: PropTypes.number
}

export default CurrencyConversion;