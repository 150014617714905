import { axiosInstance } from '../common';
import { generatePath } from 'react-router-dom';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/settings/vat-type';
const ENDPOINT_SINGLE = '/settings/vat-type/:Id';

export const getVatList = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}
export const getVatDetails = (Id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        Id
    })).then(({ data }) => data)
}
export const updateVAT = (Id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        Id,
    }), payload)//.then(({ message }) => message)
}

export const CreateVAT = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}