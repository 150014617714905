import {
	Input
} from 'antd';
import PropTypes from 'prop-types';
import { React } from 'react';
import { ValidationForm as Form } from '../';
import { getCountryById } from '../../../library/helpers';
import { CompanySelect, GenderSelect, LanguageSelect, PhoneInput, RoleSelect } from '../../inputs';
import styles from './contact.module.scss';

const Contact = ({ children, form, ...otherProps }) => {
	const validationRules = {
		first_name: [
			{
				required: true,
				message: 'First Name is required'
			}
		],
		last_name: [
			{
				required: true,
				message: 'Last Name is required'
			}
		],
		customer_id: [
			{
				required: true,
				message: 'Customer is required'
			}
		],
		phone_number: [
			{
				required: true,
				message: 'Phone Number is required'
			}
		],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is not valid'
			}
		],
		company_role: [
			{
				required: true,
				message: 'Role is required'
			}
		],
		gender_id: [
			{
				required: true,
				message: 'Gender is required'
			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
	}

	return (
		<Form form={form} name="contact" layout="vertical" validationRules={validationRules} className={styles.FormWrapper} {...otherProps}>
			<Form.Item name="first_name" label="First Name" >
				<Input />
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input />
			</Form.Item>
			<Form.Item name='customer_id' label="Company" >
				<CompanySelect
					currentSelection={{ value: otherProps?.initialValues?.customer_id, label: otherProps?.initialValues?.customer?.company }}
					onChange={(val) => {
						try {
							form.setFieldsValue({
								language_id: val,
								phone_country_code: getCountryById(val).phone_number_code,
								mobile_country_code: getCountryById(val).phone_number_code

							})
						}
						catch (e) {
							form.setFieldsValue({
								language_id: 4,
								phone_country_code: '49',
								mobile_country_code: '49'
							})

						}
					}} />
			</Form.Item>
			<Form.Item name="email" label="Email" >
				<Input type="email" />
			</Form.Item>
			<Form.Item name='company_role' label="Role (Department)" >
				<RoleSelect />
			</Form.Item>
			<Form.Item label="Phone No." required>
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} label='Phone No.' required />
			</Form.Item>
			<Form.Item label="Mobile No.">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} label='Mobile No.' />
			</Form.Item>
			<Form.Item name='gender_id' label="Gender" >
				<GenderSelect />
			</Form.Item>
			<Form.Item name='language_id' label="Language" >
				<LanguageSelect />
			</Form.Item>
			{children}
		</Form>
	)
}

Contact.propTypes = {
	children: PropTypes.node,
	// initialValues: PropTypes.object,
	form: PropTypes.object,
}

export default Contact