import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Drawer, Input, message,Select } from 'antd';
import React, { useState } from 'react';
import { Form, useActionData, useNavigate, redirect } from 'react-router-dom';
import { createVendorUser } from '../../api/vendor/vendor.js';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { getFieldErrors, getFieldStatus } from '../../library/helpers/forms/index.js';
import Styles from './user-details.module.scss';
import { generateRouteUrl } from '../../library/constants/index.js';
import { VendorUserRoles } from '../../library/constants/VendorUserRoles.js'

const VendorUserCreate = () => {
    const navigate = useNavigate()
    const { errors = null } = useActionData() || {}
    const [userType, setUserType] = useState('')

    return (
        <Drawer open={true} mask={false} title="Create User" onClose={() => navigate('../', { replace: true, relative: 'route' })} >
            <Form method="post" className={Styles.formWrapper}>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Name</label>
                        <Input type="text" name="name" required status={getFieldStatus('name', errors)} />
                        {getFieldErrors('name', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Email</label>
                        <Input type="email" name="email" required status={getFieldStatus('email', errors)} />
                        {getFieldErrors('email', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Username</label>
                        <Input type="text" name="username" required status={getFieldStatus('username', errors)} />
                        {getFieldErrors('username', errors)}
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>Type</label>
                        <Select
                            options={VendorUserRoles}
                            fieldNames={{ value: 'id', label: 'name' }}
                            onChange={c => setUserType(c)}
                            mode='multiple'
                            status={getFieldStatus('user_roles', errors)}
                        />
                        {getFieldErrors('type', errors)}
                        <input type="hidden" name="user_roles" value={userType} />
                    </div>
                </div>
                <div className={Styles.fieldsWrapper}>
                    <div className={Styles.inputField}>
                        <label>New Password</label>
                        <Input.Password
                            placeholder="New Password"
                            name="password"
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            status={getFieldStatus('username', errors)}
                            pattern="^(?=.*[0-9])(?=.*[\W_])(?=.*[a-zA-Z])(?=\S+$).{6,}$"
                            title="Password must contain at least one digit, one special character, one letter, and be at least 6 characters."
                            required
                        />
                        {getFieldErrors('username', errors)}
                    </div>
                </div>
                <Button type='primary' htmlType='submit'>Create User</Button>
            </Form>
        </Drawer >
    )
}

VendorUserCreate.Action = async ({ params, request }) => {
    const { id } = params
    const { name, email, username, password,user_roles } = Object.fromEntries(await request.formData())
    const payload = {
        name, email, username, password,
        user_roles : user_roles.split(',')
    }

    try {
        const { message: msg } = await createVendorUser(id, payload)
        message.success(msg)
        return redirect(generateRouteUrl('VendorUpdate', {
            id: id
        }), {
            replace: true
        })
    }
    catch (error) {
        message.error(error.message)
        if (error instanceof ValidationError) {
            return {
                errors: error.errors
            }
        }
    }

    return null

}

export default VendorUserCreate