import dayjs from 'dayjs'
import moment from 'moment'
import { formats } from "../constants/formats.js"

export const formatPrice = (price, currency) => {
    if (price == null) {
        return ''
    }
    try {
        const currencyCode = currency?.toUpperCase()
        const locale = (currencyCode === 'GBP') ? 'en-UK' : currencyCode === 'USD' ? 'en-US' : 'nl-NL'
        const money = Intl.NumberFormat(locale,
            { style: 'currency', currency: currencyCode }
        ).format([price])
        return money
    }
    catch (error) {
        console.debug({ price, currency })
        console.error(error)
        return '⚠' + price
    }
}

export const formatDate = (stringDate) => {
    if (!stringDate) {
        return ''
    }
    return moment(stringDate, formats.backendDateFormat).format(formats.frontendDateFormat)
}

export const formatDateTime = (stringDate) => {
    if (!stringDate) {
        return ''
    }
    return moment(stringDate, formats.backendDateTimeFormat).format(formats.frontendDateTimeFormat)
}

export const formatBackendDate = (stringDate) => {
    if (!stringDate) {
        return ''
    }
    return moment(stringDate, formats.frontendDateFormat).format(formats.backendDateFormat)
}

export const getTimeAgo = (stringDate, smart = false) => {
    const date = moment(stringDate, formats.backendDateTimeFormat)
    if (!smart || moment().diff(date, 'hours') < 24) {
        return date.fromNow()
    }
    return date.format(formats.frontendDateTimeFormat)
}

export const DateFormatted = (date) => {
    const dateObj = new Date(date)
    if (isNaN(dateObj)) {
        return "NA"
    }
    return new Intl.DateTimeFormat('de-DE').format(dateObj)
}


export const AddOrSubractDays = (startingDate, number, add) => {
    if (add) {
        return new Date(new Date().setDate(startingDate.getDate() + number));
    } else {
        return new Date(new Date().setDate(startingDate.getDate() - number));
    }
}


export const DifferenceBetweenDates = (date1, date2) => {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    console.log(date1);
    console.log(date2);
    var diffDays = parseInt((d2 - d1) / (1000 * 60 * 60 * 24), 10);

    return diffDays
}

export const DateDifference = (date1, date2, unit = 'day') => {
    const d1 = dayjs(date1)
    return d1.diff(date2, unit)
}

export const getDateDiffAsMessage = (date1, date2) => {
    const diff = DateDifference(date1, date2)
    if (diff < 0) {
        return `${Math.abs(diff)} days late`
    }
    if (diff > 0) {
        return `${diff} days ahead`
    }
    if (diff === 0) {
        return 'on time'
    }
}

export const formatNumber = (value, locale = 'nl-NL') => {
    return Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value)
}

export const convertToERPCurrency = (val, rate) => val * rate
export const convertFromERPCurrency = (val, rate) => val / rate

export const roundOfNumber = (val, precision) => {
    if (isNaN(val)) {
        // Handle invalid input
        return 0;
    }
    if (isNaN(precision) || precision < 0) {
        return Math.round(val)
    }
    return Number(val?.toFixed(precision))
}

export const localizeNumber = (val, locale, precision = 2) => {
    if (val == null || val === '') {
        return null
    }
    if (!val)
        return 0;

    return Intl.NumberFormat(locale, { maximumFractionDigits: precision }).format(val)
}

export const parseLocalizedNumber = (stringNumber, locale) => {
    var thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '')
    var decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '')

    return parseFloat(stringNumber
        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
        .replace(new RegExp('\\' + decimalSeparator), '.')
    )
}