import { invoiceStatuses } from "../constants/dynamic.js"
import { convertToTitle } from "./common.js"

const statuses = invoiceStatuses.map((status) => ({
    id: status.name,
    name: convertToTitle(status.id),
}))

export const findInvoiceStatusById = (status) => statuses.find(({ id }) => id === parseInt(status))?.name

export const InvoiceStatuses = {
    NEW: 1,
    REMINDER: 2,
    FINALIZE: 3,
    SEND_TO_CLIENT: 4,
    CLOSED_LOST: 5,
    SENT_TO_EULER: 6,
    PAID: 7,
    FIRST_REMINDER: 8,
    SECOND_REMINDER: 9,
    THIRD_REMINDER: 10,
    FOURTH_REMINDER: 11,
    CANCEL: 12
}

export const ReminderTypes = {
    FIRST: "first",
    SECOND: "second",
    THIRD: "third",
    FINAL: "fourth",
}