import { Layout } from "antd";
import { intersection } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Outlet, useRevalidator } from 'react-router-dom';
import { getNotifications } from "./api/auth/notifications.js";
import AuthManager from './auth-manager';
import { PushNotifications } from "./components/page";
import { Sidebar } from "./components/sidebar";
import { getUserSessionData, routesList, setUserSessionData } from "./library/constants";
import { AuthProvider } from './providers/auth';

export default function AuthenticatedPage() {
  const [notifications, setNotifications] = useState([])
  const revalidator = useRevalidator()

  useEffect(() => {
    // Retrieve data from local storage on component mount
    console.log('fetching existing notifications....')
    setNotifications(getUserSessionData('NOTIFICATIONS'))
  }, [])

  // These user roles can later on come from the profile based on authentication
  const userRoles = JSON.parse(localStorage.getItem('USER'))?.permisions ?? [];
  const routes = routesList.filter(
    (item) => intersection(item.roles_allowed, userRoles).length > 0/*  || true */
  );

  const updateNotifications = async () => {
    const { notifications, unreadCount } = await getNotifications()
    revalidator.revalidate()
    setUserSessionData('NOTIFICATIONS', JSON.stringify({ notifications, unreadCount }))
    setNotifications({ notifications, unreadCount })
  }

  return (
    <AuthProvider>
      <AuthManager>
        <>
          <PushNotifications onNewNotifaction={updateNotifications} />
          <Layout hasSider style={{ backgroundColor: '#f3f2f7', alignItems: 'flex-start' }}>
            <Sidebar routes={routes} />
            <Layout.Content style={{ position: 'relative' }}>
              <Outlet context={{ ...notifications, updateNotifications }} />
            </Layout.Content>
          </Layout>
        </>
      </AuthManager>
    </AuthProvider>
  );
}
