import { CloseCircleOutlined, CloseOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, FileImageOutlined, FileSearchOutlined, FileTextOutlined, InboxOutlined, PlusOutlined, SendOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Dropdown, Image, Input, Modal, Space, Statistic, Tooltip, message } from 'antd';
import { debounce, isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { Await, Link, defer, generatePath, redirect, useFetcher, useLoaderData, useNavigate, useParams, useRevalidator, useSubmit } from 'react-router-dom';
import { getCustomerDetails } from '../../api/customer';
import { addProjectContact, approveCancellationWaiveOffRequest, cancelClientQuotation, changeStatus, confirmVendor, copyItemToNewExistingProject, copyItemToNewProject, declineCancellationWaiveOffRequest, deleteImage, deleteProject, getClientQuotations, getProjectDetails, projectAssignOrderManager, removeProjectContact, removeProjectContactAuthority, removeProjectInvoiceContact, resendClientQuotation, resumeProject, setProjectContactAuthority, setProjectContactPrimary, setProjectInvoiceContact, updateProjectDetails, uploadImages, uploadVisual } from '../../api/project';
import { copyItemWithinProject, getProjectItem, updateProjectItem } from '../../api/project-item';
import { ContactDescription, ProjectItemOverview, ProjectSummary, SelectVendorforProduction, Tasktodo } from '../../components/data';
import { HubspotButton } from '../../components/data/hubspot-viewer.jsx';
import { ActionCenter, Box, SmallSection } from '../../components/design';
import { ProjectDetails } from '../../components/form';
import { CustomerContactRoleSelect, OrderManagerSelect, ProjectSelectAdvance } from '../../components/inputs';
import ContactSelect from '../../components/inputs/ContactSelect.jsx';
import FileManager from '../../components/inputs/file-manager.jsx';
import { FieldWrapper, GeneralFieldWrapper, InputWrapper } from '../../components/inputs/input-wrappers.jsx';
import Confirm from '../../components/message/Confirm.jsx';
import { CustomPrompt } from '../../components/message/custom-prompt.jsx';
import { Prompt } from '../../components/message/prompt.jsx';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { ProjectStatuses, Server, canCancelProject, canCancelQuotation, canCloseProject, canDeleteProject, canSendQuotation, canUpdateQuotation, getFilePath, getFilePathForView, getProjectStatusByID, isInactiveProject, isQuotationConfirmed } from '../../library/constants';
import { OrderStatusNameById, OrderStatuses } from '../../library/constants/OrderStatuses.js';
import { CancellationWaiveOffRequestStatuses } from '../../library/constants/cancellation-waive-off-requests.js';
import { CLIENT_QUOTATION_STATUSES } from '../../library/constants/client-quotation-statuses.js';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { getFormattedId } from '../../library/helpers/index.js';
import { formDataToJson } from '../../library/utilities/formdata.js';
import { formatDate, formatPrice, getTimeAgo } from '../../library/utilities/intl.js';
import { ProjectCancellationForm } from './project-cancellation-form.jsx';
import styles from './project-update.module.scss';
const { TextArea } = Input;
const { Panel } = Collapse



const getProjectFilePreviewUrl = (url, projectId) => {
	const extention = url.split('.').pop()
	switch (extention) {
		case "svg":
		case "png":
		case "jpg":
			return getFilePathForView(url, projectId)
		case "ai":
			return "/icons/filetypes/ai.svg"
		case "pdf":
			return "/icons/filetypes/pdf.svg"
		default:
			return "/icons/filetypes/file.svg"
	}
}

const ImageManager = () => {
	const [isVisible, setIsVisible] = useState(false)
	const { projectDetails } = useLoaderData()
	const { projectId } = useParams()
	const [isLoading, setIsLoading] = useState(false)
	let revalidator = useRevalidator();

	const handleFileUpload = async (imageData) => {
		try {
			setIsLoading(true)
			const msg = await uploadImages(projectId, imageData)
			message.success(msg);
			revalidator.revalidate()
			setIsLoading(false)
		}
		catch (error) {
			setIsLoading(false)
			throw new Response('Unable to upload files', { status: 500 })
		}
	}

	const handleDeleteFile = async (url) => {
		try {
			setIsLoading(true)
			const msg = await deleteImage(projectId, url, 'project_images')
			message.success(msg)
			revalidator.revalidate()
			setIsLoading(false)
		}
		catch (error) {
			setIsLoading(false)
			message.error(error.message)
		}
	}

	return (
		<>
			<GeneralFieldWrapper label="Project Images">
				<Button icon={<FileImageOutlined />} onClick={() => setIsVisible(true)}>Add More Images</Button>
			</GeneralFieldWrapper>
			<FileManager
				title="Upload Project Images"
				files={projectDetails?.documents?.project_images}
				isOpen={isVisible}
				projectId={projectId}
				onUpload={handleFileUpload}
				onDelete={handleDeleteFile}
				onClose={() => setIsVisible(false)}
				loading={isLoading || revalidator.state !== 'idle'}
			/>
		</>
	)
}

const UploadVisual = () => {
	const [isVisible, setIsVisible] = useState(false)
	const { projectDetails } = useLoaderData()
	const { projectId } = useParams()
	const [isLoading, setIsLoading] = useState(false)

	let revalidator = useRevalidator();

	const handleFileUpload = async (imageData) => {
		try {
			setIsLoading(true)
			const msg = await uploadVisual(projectId, imageData)
			message.success(msg);
			revalidator.revalidate()
			setIsLoading(false)
		}
		catch (error) {
			setIsLoading(false)
			throw new Response('Unable to upload files', { status: 500 })
		}
	}

	const handleDeleteFile = async (url) => {
		try {
			setIsLoading(true)
			const msg = await deleteImage(projectId, url, 'visual')
			message.success(msg)
			revalidator.revalidate()
			setIsLoading(false)
		}
		catch (error) {
			setIsLoading(false)``
			message.error(error.message)
		}
	}

	return (
		<>
			<GeneralFieldWrapper label="Upload Visuals">
				<Button icon={<FileImageOutlined />} onClick={() => setIsVisible(true)}>Upload Visuals</Button>
			</GeneralFieldWrapper>
			<FileManager
				title="Upload Visuals"
				files={projectDetails?.documents?.visual}
				isOpen={isVisible}
				projectId={projectId}
				onUpload={handleFileUpload}
				onDelete={handleDeleteFile}
				onClose={() => setIsVisible(false)}
				loading={isLoading || revalidator.state !== 'idle'}
			/>
		</>
	)
}

// const UploadVisual = () => {
// 	const { projectDetails } = useLoaderData()
// 	const [isVisible, setIsVisible] = useState(false)
// 	const [isDeleting, setIsDeleting] = useState(false)
// 	const fileUploaderRef = useRef(null)
// 	const { projectId } = useParams()
// 	let revalidator = useRevalidator();
// 	const uploadedFile = projectDetails?.documents?.visual?.[0]

// 	const handleFileUpload = async () => {
// 		if (!fileUploaderRef.current) {
// 			alert('Please select a file')
// 			return
// 		}

// 		try {
// 			const fileId = await uploadFileFromObject(null, fileUploaderRef.current)

// 			const msg = await uploadVisual(projectId, fileId)
// 			message.success(msg);
// 			// setIsVisible(false);
// 			fileUploaderRef.current = null
// 			revalidator.revalidate()

// 		}
// 		catch (error) {
// 			throw new Response('Unable to upload file', { status: 500 })
// 		}

// 	}

// 	const handleDeleteFile = async (url) => {
// 		setIsDeleting(true)
// 		try {
// 			const msg = await deleteVisual(projectId, url)
// 			message.success(msg)
// 			revalidator.revalidate()
// 		}
// 		catch (error) {
// 			message.error(error.message)
// 		}
// 		setIsDeleting(false)
// 	}

// 	return (
// 		<>
// 			<GeneralFieldWrapper label="Visual">
// 				<Button icon={<FileImageOutlined />} onClick={() => setIsVisible(true)}>{uploadedFile ? 'View Uploaded Visual' : 'Upload Visual'}</Button>
// 			</GeneralFieldWrapper>
// 			<Drawer open={isVisible} onClose={() => setIsVisible(false)} size="large" footer={
// 				<div className="actions align--right">
// 					<Button onClick={() => handleFileUpload()} icon={<UploadOutlined />} type="primary">Upload</Button>
// 					<Button onClick={() => setIsVisible(false)} type="danger">Close</Button>
// 				</div>
// 			}>
// 				{!!uploadedFile && (
// 					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
// 						<img src={getProjectFilePreviewUrl(uploadedFile.url, projectId)} width={200} />
// 						<p className={styles.label}>{uploadedFile.name}</p>
// 						<div>
// 							<Button onClick={() => { handleDeleteFile(uploadedFile.url) }} icon={<DeleteOutlined />} loading={isDeleting} >Delete</Button>
// 							<Link to={getFilePath(uploadedFile.url, projectId)} download={uploadedFile.name}><Button icon={<EyeOutlined />}>Download</Button></Link>
// 						</div>
// 					</div>
// 				)}
// 				<SingleFileUpload ref={{ fileUploaderRef }} />
// 			</Drawer>
// 		</>
// 	)
// }

const Contact = ({ contact, primaryContactId, invoiceContactId, children }) => {
	const fetcher = useFetcher()
	const handleContactRoleChange = ({ action, contactId, type }) => {
		switch (type) {
			case 'primary':
				fetcher.submit({
					action: 'primaryContact',
					contactId: contactId
				}, {
					method: 'post',
				});
				break;

			case 'authority':
				fetcher.submit({
					action: 'authorityContact',
					contactId: contactId
				}, {
					method: action === 'add' ? 'post' : 'delete',
				});
				break;

			case "invoice":
				fetcher.submit({
					action: 'invoiceContact',
					contactId: contactId
				}, {
					method: action === 'add' ? 'post' : 'delete',
				});
				break;

			default:
				break;
		}
	}

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<CustomerContactRoleSelect
					contactId={contact.id}
					isPrimary={contact.id === primaryContactId}
					isAuthority={!!contact.is_authority}
					isInvoice={contact.id === invoiceContactId}
					onChange={handleContactRoleChange}
				/>
				{contact.id !== primaryContactId && <Confirm
					content='Do you want to remove the contact from this project ?'
					icon={<DeleteOutlined />}
					onConfirm={() => {
						fetcher.submit({
							action: 'removeContact',
							contactId: contact.id
						}, {
							method: 'post',
						})
					}}
					loading={fetcher.state === 'submitting'}
					type="primary"
					buttonProps={{ size: "small" }}
					danger
				/>}
			</div>
			<SmallSection containerStyles={{ opacity: fetcher.state === 'submitting' ? 0.7 : 1 }}>
				<ContactDescription contact={contact} />
				{children}
			</SmallSection>
		</>
	)
}
Contact.propTypes = {
	contact: PropTypes.object.isRequired,
	primaryContactId: PropTypes.number.isRequired,
	invoiceContactId: PropTypes.number,
	children: PropTypes.node,
}
const ProjectUpdate = ({ title }) => {
	const { projectId, items, contacts, projectDetails, basicProjectDdetails, customerDetails, clientQuotations } = useLoaderData()
	const [orderManagerId, setOrderManagerId] = useState(projectDetails?.order_manager_id)
	const formIsValid = useRef(true)
	const cancellableItems = useMemo(() => items.filter(({ active, order_status }) => (active === 1 && (order_status < OrderStatuses.READY_FOR_PRODUCTION || order_status === OrderStatuses.CANCEL_REQUEST))), [items])

	//const [contacts, { add: addContact, remove: removeContact, update: updateContact, set: setContacts }] = useListReducer(projectDetails.contacts)
	const [itemCopyToProject, setItemCopyToProject] = useState({})
	const navigate = useNavigate()
	const fetcher = useFetcher()
	const submit = useSubmit()

	const isClientQuotationSent = (projectDetails.status.id >= ProjectStatuses.QUOTATION_SENT)

	// const [projectSelect, setProjectSelect] = useState(false)
	// const [project_id, setProject_id] = useState(projectDetails.id)
	const handleCustomerChange = async (/* customer */) => {
	}
	const handleProjectDetailsChange = async (_, values) => {
		if (!formIsValid.current) {
			return false
		}
		fetcher.submit({
			...values,
			action: 'updateBasicDetails'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	const handleCombiStatusChange = async (itemId) => {
		fetcher.submit({
			itemId,
			action: 'updateCombiStatus'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	const handleDelete = async (itemId) => {
		fetcher.submit({
			action: 'deleteItem'
		}, {
			action: generateRouteUrl('ProjectItemUpdate', {
				itemId,
				projectId
			}),
			method: 'post',
			encType: 'application/json'
		})
	}

	const copyItemToExistingProject = () => {
		fetcher.submit({
			itemId: itemCopyToProject.itemId,
			projectId: itemCopyToProject.projectId,
			action: 'copyItemToExistingProject'
		}, {
			method: 'post',
			encType: 'application/json'
		})
		setItemCopyToProject({})
	}

	const handleCopyItem = (key, itemId) => {
		switch (key) {
			case "copytonew":
				fetcher.submit({
					itemId,
					action: 'copyItemToNewProject'
				}, {
					method: 'post',
					encType: 'application/json'
				})
				break
			case "copytocurrent":
				fetcher.submit({
					itemId,
					action: 'copyItemToCurrentProject'
				}, {
					method: 'post',
					encType: 'application/json'
				})
				break

			case "copytoexisting":
				setItemCopyToProject(curr => ({
					...curr,
					itemId
				}))
				break
			default:
				message.error('Invalid Action')
		}
	}

	// const getAddtionalQuotationData = async (itemId) => {
	// 	try {
	// 		await getAdditonalQuoataion(itemId).then((data) => {
	// 			setAdditonalQuotation(data)
	// 		})
	// 	}
	// 	catch (e) {
	// 		message.error(e?.messsage)
	// 	}
	// }
	const debouncedSubmit = debounce((data) => {
		fetcher.submit({
			...data,
			action: 'updateBasicDetails'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}, 500)

	// const handleFormChange = (index, event) => {
	// 	let data = [...inputFields];
	// 	data[index][event.target.name] = event.target.value;
	// 	setInputFields(data);
	// }

	// const handleAddMore = () => {
	// 	let newfield = { name: '', price: '', description: '' }

	// 	setInputFields([...inputFields, newfield])
	// }

	return (
		<Page className='projects-create' backLink={generatePath('/projects')} title={title} header={
			<div className='headerStats'>
				<Statistic
					title="Project ID"
					value={getFormattedId(projectId)}
					formatter={v => v}
					valueStyle={{
						color: '#5A5A5A',
						backgroundColor: '#E6E6E6'
					}}
				/>
				<Statistic
					title="Project Status"
					value={projectDetails.status.name}
				/>
				{!!projectDetails.total_price && (
					<Statistic
						title="Total Amount"
						value={formatPrice(projectDetails.total_price, projectDetails.entity.currency.code)}
					/>
				)}
				{!!customerDetails.bad_payment_history && (
					<Statistic
						title="Bad Payment History"
						value="Yes"
						valueStyle={{ backgroundColor: 'red', color: 'white' }}
					/>
				)}
				<Statistic
					title="Payment term"
					value={projectDetails.payment_term?.name}
				/>
				{ProjectStatuses.CONFIRMATION_CLIENT_SENT === projectDetails.status.id
					&&
					<div className={styles.confirmLetter}>
						<p className={styles.smallFont}>Client Confirmation Letter</p>
						<Link target='blank' to={getFilePath(projectDetails?.client_quotation?.pdf?.approved_quotation, projectId)}>
							<Button icon={<DownloadOutlined />} type='primary'>Download PDF</Button></Link>
					</div>
				}
				<Link to={generateRouteUrl('CustomerDetails', {
					id: projectDetails.customer_id
				})}>
					<Statistic
						title="Customer"
						value={projectDetails.customer.company}
					/>
				</Link>
				{!!projectDetails.order_manager && (
					<Statistic
						title="Order Manager"
						value={projectDetails.order_manager.name}
					/>
				)}
				<Statistic
					title="Language"
					value={Server.getPublicFileUrl(`upload/flag/${projectDetails.language.cms_language_code}.png`)}
					formatter={v => (
						<Tooltip title={projectDetails.language.name}><img src={v} width={24} /></Tooltip>
					)}
				/>
				<Statistic
					title="Created At"
					value={projectDetails.createdAt}
					formatter={getTimeAgo}
				/>
			</div>
		}>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Space direction="vertical">
					{projectDetails.project_waiveoff_requests?.filter(({ status }) => status === CancellationWaiveOffRequestStatuses.NEW)?.map(request => (
						<Alert
							key={request.id}
							type="info"
							message="Sales manager has requested for cancellation fee waive off."
							action={
								<CustomPrompt
									title="Review Cancellation Fee Waive off Request"
									modalIcon={false}
									cancelText="Cancel"
									okText="Deny"
									width={720}
									additionalActions={{
										approve: {
											text: 'Approve',
											onClick: ({ comment = null }) => {
												if (comment.length === 0) {
													message.error('Please specifiy the reason')
													return false
												}
												fetcher.submit({
													action: 'approveWaiveoffRequest',
													comment,
													requestId: request.id,
												}, {
													method: 'post',
													encType: 'application/json'
												})
											}
										}
									}}
									onConfirm={({ comment = null }) => {
										if (comment.length === 0) {
											message.error('Please specifiy the reason')
											return false
										}
										fetcher.submit({
											action: 'declineWaiveoffRequest',
											comment,
											requestId: request.id,
										}, {
											method: 'post',
											encType: 'application/json'
										})
									}}
									buttonText="Review Request"
								>
									{items.filter(({ id }) => request.item_ids.includes(id))?.map(item => (
										<div key={item.id}>
											<strong>{item.product.name}: </strong>
											<div>Cancellation Reason: {request.cancellation_reason}</div>
											<div>Waiveoff Reason: {request.request_comment}</div>
										</div>
									))}
									<InputWrapper name="comment" label="Comment" rows={4} textarea />
								</CustomPrompt>
							}
							showIcon
						/>
					))}
					{items.some(({ order_status }) => order_status === OrderStatuses.CANCEL_REQUEST) && (
						<Alert
							type="error"
							message="Order manager has requested Cancellation of following items"
							description={items.filter(({ order_status }) => order_status === OrderStatuses.CANCEL_REQUEST).map(item => (
								<div key={item.id}>
									<strong>{item.product.name}: </strong>
									{item.comments?.cancel_request}
								</div>
							))}
							action={
								<Prompt
									title="Resume Project"
									modalIcon={false}
									icon={<CloseCircleOutlined />}
									okText="Resume"
									cancelText="Cancel"
									onConfirm={({ items = [], comment }) => {
										if (items.length === 0) {
											message.error('Please select atleast one item')
											return false
										}
										fetcher.submit({
											action: 'resumeProject',
											items,
											comment,
										}, {
											method: 'post',
											encType: 'application/json'
										})
									}}
									buttonText="Resume Project"
									type="primary"
								>
									<ProjectCancellationForm items={items.filter(({ order_status }) => order_status === OrderStatuses.CANCEL_REQUEST).map(({ id, product }) => ({
										id,
										name: product.name,
										selected: true,
									}))} />
								</Prompt>
							}
							showIcon
						/>
					)}
				</Space>
				<div className={styles.spacer}></div>
				<ActionCenter actions={[{
					action: (
						<Confirm
							content='Do you really want to delete this project ?'
							icon={<DeleteOutlined />}
							onConfirm={() => {
								fetcher.submit({
									action: 'deleteProject',
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'deleteProject'}
							type="primary"
							danger
						>Delete Project</Confirm>
					),
					isVisible: canDeleteProject(projectDetails.status.id),
				}, {
					action: (
						<Confirm
							content='Do you really want to close this project ?'
							icon={<CloseOutlined />}
							onConfirm={() => {
								fetcher.submit({
									action: 'closedLost',
								}, {
									method: 'post',
									encType: 'application/json'
								})
							}}
							loading={fetcher.json?.action === 'closedLost'}
							type="primary"
							danger
						>Closed Lost</Confirm>
					),
					isVisible: canCloseProject(projectDetails.status.id),
				}, {
					action: (
						<Prompt
							title="Confirm Cancellation"
							modalIcon={false}
							icon={<CloseCircleOutlined />}
							okText="Proceed"
							cancelText="Cancel"
							onConfirm={({ items = [], comment }) => {
								if (items.length === 0) {
									message.error('Please select atleast one item')
									return false
								}
								submit({
									items: Array.isArray(items) ? items.join(',') : items,
									comment
								}, {
									action: generateRouteUrl('ProjectCancellationInvoiceCreate', {
										projectId
									}),
									method: 'get'
								})
							}}
							buttonText="Cancel Project"
							type="primary"
							danger
						>
							<ProjectCancellationForm items={cancellableItems.map(({ id, product, order_status }) => ({
								id,
								name: product.name,
								selected: order_status === OrderStatuses.CANCEL_REQUEST
							}))} />
						</Prompt>
					),
					isVisible: (canCancelProject(projectDetails.status.id) && cancellableItems.length > 0),
				}, {
					action: (!!projectDetails.hubspot_url && <HubspotButton url={projectDetails.hubspot_url} />),//Prevents warning
					isVisible: !!projectDetails.hubspot_url
				}
				]} />
				{!!projectDetails.project_rejected_reason && (
					<Collapse defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header={'Closed Lost Reason'} key="1" >
							{projectDetails.project_rejected_reason}
						</Panel>
					</Collapse>
				)}
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><img src="/icons/data.svg" style={{ width: '1rem', verticalAlign: 'text-top', marginRight: '8px' }} />Project Information</>} key="1" >
						<ProjectDetails
							initialValues={basicProjectDdetails}
							getValidationStatus={(isValid) => { formIsValid.current = !!isValid }}
							onCustomerChange={handleCustomerChange}
							onValuesChange={handleProjectDetailsChange}
							companyName={projectDetails.customer.company}
							isEditing={true}
							projectStatus={projectDetails.status.id}
							disabled
						/>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={isClientQuotationSent ? [] : [1]} expandIconPosition="end">
					<Panel header={<><UserOutlined /> Contact Information</>} key="1">
						{Object.entries(contacts).map(([, contact]) => (
							<Contact key={contact.id} contact={contact} primaryContactId={projectDetails?.primary_contact_id} invoiceContactId={projectDetails.billing_contact_id} >
								{contact.client_url && <Button href={contact.client_url} type="link" target="_blank">View Client Environment</Button>}
							</Contact>
						))}
						{!isInactiveProject(projectDetails.status.id) && (
							<>
								<div className="ant-collapse-footer">
									<ContactSelect
										customerId={customerDetails.id}
										onSelect={(contactId) => {
											fetcher.submit({
												action: 'addContact',
												contactId
											}, {
												method: 'post',
											})
										}}
										country_id={customerDetails.country_id}
										excludedContactIds={Object.values(contacts).map(({ id }) => id)}
										allowedLanguages={customerDetails.entity?.default_language}
										renderSelected={() => (
											<Button icon={<UserAddOutlined />} type="primary" ghost>Add New Contact</Button>
										)}
									/>
								</div>
							</>
						)}
					</Panel>
				</Collapse>
				<Collapse key={`itemdetails-${projectDetails.status.id}-${projectDetails.order_manager_id}`} defaultActiveKey={(isInactiveProject(projectDetails.status.id) || (!isQuotationConfirmed(projectDetails.status.id) || !!projectDetails.order_manager_id)) ? ['1'] : []} expandIconPosition="end">
					<Panel header={<><InboxOutlined /> Item Details</>} key="1">
						{Object.values(items).map(item => (
							<ProjectItemOverview
								key={item.id}
								data={item}
								includeVat={basicProjectDdetails.include_vat}
								actions={{
									status: <Tooltip title="Status"><Button type='actionpanel' shape='square' ghost>{OrderStatusNameById(item.order_status)}</Button></Tooltip>,
									...(!!item.client_expected_delivery_date && { deadline: <Tooltip title="Requested Deadline Date By Client"><Button type='actionpanel' shape='square' ghost>{formatDate(item.client_expected_delivery_date)}</Button></Tooltip> }),
									edit: <Link to={generateRouteUrl('ProjectItemUpdate', { projectId, itemId: item.id })}><Button icon={<EditOutlined />} shape="square" type="primary" ghost /></Link>,
									copy: (
										<>
											<Dropdown
												// icon={<CopyOutlined />}
												menu={{
													items: [
														{
															label: 'To New Project',
															key: 'copytonew',
														},
														{
															label: 'To Existing Project',
															key: 'copytoexisting',
														},
														{
															label: 'To Current Project',
															key: 'copytocurrent',
															disabled: !canUpdateQuotation(projectDetails.status.id)
														},
													],
													onClick: ({ key }) => handleCopyItem(key, item.id)
												}}
											><Button icon={<CopyOutlined />} shape="square" type="actionpanel" ghost>Copy Item</Button></Dropdown>
										</>
									),
									...((ProjectStatuses.CONFIRMATION_CLIENT_SENT === projectDetails.status.id && item.active === 1) && { additionaQuotation: <Link to={generateRouteUrl('AdditionalQuotation', { projectId, itemId: item.id })}><Button shape="square" type="actionpanel" ghost>Additional Quotation</Button></Link> }),
									...((ProjectStatuses.CONFIRMATION_CLIENT_SENT === projectDetails.status.id && item.active === 1 && item.is_combi) && { combiDeals: <Button onClick={() => handleCombiStatusChange(item.id)} shape="square" type="actionpanel" disabled={item.combi} ghost>{item.combi ? 'Eligible for Combi' : 'Combi Suggestion'}</Button> }),
									...(canUpdateQuotation(projectDetails.status.id) && { delete: <Confirm icon={<DeleteOutlined />} onConfirm={() => handleDelete(item.id)} title="Delete Item" content="You will lost all item related data, and this action cannot be undone. Do you really want to proceed ?" shape="square" danger ghost /> }),
								}}
							/>
						))}
						{!!itemCopyToProject.itemId && (
							<Modal open={true} onOk={() => copyItemToExistingProject()} okText="Copy Item" onCancel={() => setItemCopyToProject({})} maskClosable>
								<GeneralFieldWrapper label="Select an existing open project">
									<ProjectSelectAdvance
										name="project_id"
										value={itemCopyToProject.projectId}
										filters={{
											status: [ProjectStatuses.NEW],
										}}
										onChange={v => setItemCopyToProject(curr => ({
											...curr,
											projectId: v
										}))}
									/>
								</GeneralFieldWrapper>
							</Modal>
						)}
						{canUpdateQuotation(projectDetails.status.id) && (
							<div className="ant-collapse-footer">
								<Button onClick={() => {
									ProjectStatuses.CONFIRMATION_CLIENT_SENT === projectDetails.status ?
										message.warning('Cannot add more item because the quotation is approved form the client') :
										navigate(generateRouteUrl('ProjectItemCreate', {
											projectId
										}))
								}} icon={<PlusOutlined />} type="primary" ghost>Add Item</Button>
							</div>
						)}
					</Panel>
				</Collapse>
				{ProjectStatuses.CONFIRMATION_CLIENT_SENT === projectDetails.status.id && (
					<>
						<Collapse defaultActiveKey={projectDetails.order_manager_id ? [] : ['1']} expandIconPosition="end">
							<Panel header='Tasks To Do' key="1">
								<Box type={Box.BoxTypes.GRAY}>
									<div className="form-fields-wrapper">
										<InputWrapper
											name="client_order_number"
											label="Client Order Number"
											defaultValue={projectDetails.client_order_number}
											onChange={(e) => debouncedSubmit({ client_order_number: e.target.value })}
											errors={fetcher.data?.errors}
											delay={true}
										/>
										<InputWrapper
											name="project_status"
											label="Order Status"
											value={projectDetails.status.name}
											disabled
										/>
									</div>
								</Box>
								<Tasktodo />
								<Box type={Box.BoxTypes.GRAY}>
									<FieldWrapper name="order_manager_id" label="Order Manager" errors={fetcher.data?.errros}>
										<OrderManagerSelect value={orderManagerId} onChange={setOrderManagerId} />
									</FieldWrapper>
								</Box>
							</Panel>
						</Collapse>
						<Collapse defaultActiveKey={projectDetails.order_manager_id ? [] : ['1']} expandIconPosition="end">
							<Panel header='Select Vendor for Production' key="1">
								<SelectVendorforProduction items={items.filter(({ active, order_status }) => (active === 1 && order_status !== OrderStatuses.CANCELED))} orderManagerId={orderManagerId} />
							</Panel>
						</Collapse>
						<Collapse defaultActiveKey={['1']} expandIconPosition="end">
							<Panel header='Project Summary' key="1">
								<ProjectSummary />
							</Panel>
						</Collapse>
					</>
				)}
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Comment From Client' key="1">
						<TextArea defaultValue={projectDetails.client_comment} placeholder="Client Notes" onChange={(e) => debouncedSubmit({ client_comment: e.target.value })} />
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Comment For Administration' key="1">
						<TextArea defaultValue={projectDetails.internal_comment} placeholder="Notes" onChange={(e) => debouncedSubmit({ internal_comment: e.target.value })} />
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><FileTextOutlined /> Documents</>} key="1">
						<div className="actions align--left">
							{canCancelQuotation(projectDetails.status.id) && (
								<GeneralFieldWrapper label=" ">
									<Confirm title="Cancel Client Quotation" content="Do you really want to delete client quotation ?" onConfirm={() => {
										fetcher.submit({ action: 'cancelClientQuotation' }, { method: 'post' })
									}} icon={<CloseOutlined />} type="primary" ghost>Cancel Sent Quotation</Confirm>
								</GeneralFieldWrapper>
							)}
							{canCancelQuotation(projectDetails.status.id) && (
								<GeneralFieldWrapper label=" ">
									<Button onClick={() => {
										fetcher.submit({ action: 'resendClientQuotation' }, { method: 'post' })
									}} icon={<SendOutlined />} type="primary" ghost>Resend Quotation to Client</Button>
								</GeneralFieldWrapper>
							)}
							{canSendQuotation(projectDetails.status.id) && (
								<GeneralFieldWrapper label=" ">
									<Button onClick={() => {
										if (projectDetails.items.length == 0) {
											message.error('Please add at least one item !')
										}
										else if (!projectDetails.account_manager_id) {
											message.error('Please select account manager')
										}
										else {
											navigate(generateRouteUrl('SendClientQuotation', {
												projectId,
											}))
										}
									}} icon={<FileSearchOutlined />} type="primary" ghost>Preview & Send to Client</Button>
								</GeneralFieldWrapper>
							)}
							<ImageManager />
							<UploadVisual />
							<React.Suspense>
								<Await
									resolve={clientQuotations}
									errorElement={
										<p>Error loading Data</p>
									}
								>
									{(quotations) => {
										const approvedQuotation = quotations.find(({ status }) => status === CLIENT_QUOTATION_STATUSES.APPROVED)
										const activeQuotation = quotations.find(({ status }) => status === CLIENT_QUOTATION_STATUSES.ACTIVE)

										if (!approvedQuotation && !activeQuotation) {
											return
										}
										return (<>
											{!!approvedQuotation && (
												<GeneralFieldWrapper label="Client's Signature">
													{approvedQuotation.signature ?
														(
															<Image src={getProjectFilePreviewUrl(approvedQuotation.signature, projectId)} width={100} height={32} style={{ border: '1px #c1c0c0 solid', borderRadius: '10px' }} />
														) :
														(
															<Button disabled>Pending</Button>
														)
													}
												</GeneralFieldWrapper >
											)}
											<GeneralFieldWrapper label=" ">
												{approvedQuotation?.pdf?.approved_quotation ?
													(
														<a href={getFilePath(approvedQuotation.pdf.approved_quotation, projectDetails.id)} download><Button>Download Approved Quotation</Button></a>) :
													activeQuotation?.pdf?.quotation ?
														(
															<a href={getFilePath(activeQuotation.pdf.quotation, projectDetails.id)} download><Button>Download Sent Quotation</Button></a>) : <></>
												}
											</GeneralFieldWrapper >
										</>)
									}}
								</Await>
							</React.Suspense>
						</div>
					</Panel>
				</Collapse>
			</div>
		</Page >
	)
}
ProjectUpdate.propTypes = {
	title: PropTypes.string
}
ProjectUpdate.Actions = {
	updateBasicDetails: async ({ params, data }) => {
		const { projectId } = params
		try {
			await updateProjectDetails(projectId, data)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errros
				}
			}
		}
		return false
	},
	deleteProject: async ({ params }) => {
		const { projectId } = params
		try {
			const msg = await deleteProject(projectId)
			message.success(msg)
			return redirect(generateRouteUrl('ProjectList'))
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	updateCombiStatus: async ({ data }) => {
		const { itemId } = data
		try {
			await updateProjectItem(itemId, { combi: 1 })
			message.success('Item marked as eligible for combi')
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errros
				}
			}
		}
		return false

	},
	copyItemToCurrentProject: async ({ data }) => {
		const { itemId } = data
		try {
			const msg = await copyItemWithinProject(itemId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errros
				}
			}
		}
		return false
	},
	copyItemToNewProject: async ({ params, data }) => {
		const { itemId } = data
		const { projectId } = params
		try {
			const { message: msg, data } = await copyItemToNewProject(projectId, { ids: [itemId] })
			message.success(msg)
			return redirect(generatePath('/projects/:id', {
				id: data.project_id
			}))
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errros
				}
			}
		}
		return false
	},
	copyItemToExistingProject: async ({ data }) => {
		try {
			const { itemId, projectId } = data
			const msg = await copyItemToNewExistingProject(projectId, itemId)
			message.success(msg)
			return redirect(generatePath('/projects/:id', {
				id: projectId
			}))
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				return {
					errors: error.errros
				}
			}
		}
		return false
	},
	addContact: ({ params, data }) => addProjectContact(params.projectId, data.contactId),
	removeContact: ({ params, data }) => removeProjectContact(params.projectId, data.contactId),
	primaryContact: ({ params, data }) => setProjectContactPrimary(params.projectId, data.contactId),
	authorityContact: ({ params, data, method }) => {
		if (method.toLowerCase() === 'delete') {
			return removeProjectContactAuthority(params.projectId, data.contactId)
		}
		else {
			return setProjectContactAuthority(params.projectId, data.contactId)
		}
	},
	invoiceContact: ({ params, data, method }) => {
		if (method.toLowerCase() === 'delete') {
			return removeProjectInvoiceContact(params.projectId, data.contactId)
		}
		else {
			return setProjectInvoiceContact(params.projectId, data.contactId)
		}
	},
	selectProductionVendor: async ({ params, data }) => {
		const { projectId } = params
		const { selectedVendor = null, orderManagerId } = formDataToJson(data)
		console.log({ selectedVendor, orderManagerId })
		if (!parseInt(orderManagerId)) {
			message.error('Please select an Order Manager')
			return false
		}
		if (isEmpty(selectedVendor)) {
			message.error('Please select a vendor')
			return false
		}
		try {
			const payload = Object.entries(selectedVendor).map(([itemId, vendorId]) => ({
				item_id: parseInt(itemId),
				vendor_id: parseInt(vendorId)
			}))
			await projectAssignOrderManager(projectId, parseInt(orderManagerId))
			await confirmVendor(projectId, { data: payload })
			message.success('Vendor Selected for Production and Order Manager Assigned')
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	cancelClientQuotation: async ({ params }) => {
		try {
			const projectId = parseInt(params.projectId)
			const msg = await cancelClientQuotation(projectId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	resendClientQuotation: async ({ params }) => {
		try {
			const projectId = parseInt(params.projectId)
			const msg = await resendClientQuotation(projectId)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	closedLost: async ({ params }) => {
		try {
			await changeStatus(parseInt(params.projectId), ProjectStatuses.CLOSED_LOST)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	reOpen: async ({ params }) => {
		try {
			await changeStatus(parseInt(params.projectId), ProjectStatuses.NEW)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	resumeProject: async ({ params, data }) => {
		const { projectId } = params
		try {
			const { items, comment } = data
			const msg = await resumeProject(projectId, items, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	approveWaiveoffRequest: async ({ params, data }) => {
		const { projectId } = params
		try {
			const { requestId, comment } = data
			const msg = await approveCancellationWaiveOffRequest(projectId, requestId, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
	declineWaiveoffRequest: async ({ params, data }) => {
		const { projectId } = params
		try {
			const { requestId, comment } = data
			const msg = await declineCancellationWaiveOffRequest(projectId, requestId, comment)
			message.success(msg)
			return true
		}
		catch (error) {
			message.error(error.message)
		}
		return false
	},
}
ProjectUpdate.Loader = async ({ params }) => {
	if (isNaN(params.projectId)) {
		throw new Error('Invalid Project ID')
	}
	const projectDetails = await getProjectDetails(params.projectId)
	if (!projectDetails) {
		throw new Error('Invalid Project')
	}
	//Get Project status details
	projectDetails.status = getProjectStatusByID(projectDetails.status == null ? 1 : projectDetails.status)
	const customerDetails = await getCustomerDetails(projectDetails.customer_id)
	const basicProjectDdetails = (({
		source_id, customer_id, customer, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id, include_vat
	}) => ({
		source_id, customer_id, customer, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id, include_vat
	}))(projectDetails)
	const { id: projectId, contacts } = projectDetails
	const items = await Promise.all(projectDetails.items.map(async ({ id }) => {
		return await getProjectItem(id)
	}))

	//Sort contact so primary is on top
	const contactPrioriy = [projectDetails.primary_contact_id]
	if (projectDetails.billing_contact_id) {
		contactPrioriy.push(projectDetails.billing_contact_id)
	}
	contacts.sort((a, b) => {
		// Check if either contact has a priority
		const aPriorityIndex = contactPrioriy.indexOf(a.id);
		const bPriorityIndex = contactPrioriy.indexOf(b.id);

		// If both IDs are in the priority list, sort based on their index in the priorityIds array
		if (aPriorityIndex !== -1 && bPriorityIndex !== -1) {
			return aPriorityIndex - bPriorityIndex;
		}

		// If only a is in the priority list, it should come first
		if (aPriorityIndex !== -1) {
			return -1;
		}

		// If only b is in the priority list, it should come first
		if (bPriorityIndex !== -1) {
			return 1;
		}

		//if any one of them is authority it should come first
		if (a.is_authority && !b.is_authority) {
			return -1;
		}
		if (!a.is_authority && b.is_authority) {
			return 1;
		}

		// If neither are in the priority list, sort by id
		return a.id - b.id;
	})

	const clientQuotations = getClientQuotations(params.projectId)
	return defer({ projectId, contacts, items, projectDetails, basicProjectDdetails, customerDetails, clientQuotations })
}
export default ProjectUpdate