import Langauge from '../dynamics/languages'
export const supportedLanguages = [
    'nl',
    'be',
    'be_fr',
    'fr',
    'lu_fr',
    'lu_de',
    'de',
    'en',
    'dk',
    'es',
    'ch',
    'at',
    'it',
]
export const getLanguageById = (language_id) => Langauge.find(({ id }) => id === language_id)