import { Alert, Button, Drawer, Modal, Rate, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from "react";
import { useFetcher, useRouteLoaderData } from 'react-router-dom';
import { z } from "zod";
import { addItemSupplierrRating } from '../../../api/order/supplier-finisher-flow.js';
import { Box } from '../../../components/design/box.js';
import { DocumentsDownloader } from '../../../components/form/index.js';
import { GeneralFieldWrapper } from '../../../components/inputs/input-wrappers.jsx';
import { ValidationError } from '../../../library/classes/exceptions/index.js';
import { getCountryById } from '../../../library/helpers/country.js';
import { getDateDiffAsMessage } from '../../../library/utilities/intl.js';
import styles from "./supplier-delivered.module.scss";

const ratingSchema = z.object({
    delivery: z.number({ required_error: 'Delivery rating is required' }).min(1).max(5),
    response: z.number({ required_error: 'Response rating is required' }).min(1).max(5),
    quality: z.number({ required_error: 'Quality rating is required' }).min(1).max(5),
})

const getFormattedAddress = (shipment) => (
    <div className="address">
        {shipment.street} {shipment.street_number}<br />
        {shipment.city}, {shipment.county}, {shipment.zipcode}<br />
        {getCountryById(shipment.country_id)?.name}<br />
    </div>
)

const ItemDeliveryDetails = ({ projectId, shipment }) => (
    <Box type={Box.BoxTypes.BLUE} header={(
        <>
            <div style={{ display: 'grid', gridTemplateColumns: '120px 120px 120px auto', fontWeight: 'bold' }}>
                <div>Quantity</div>
                <div>Shipped Quantity</div>
                <div>Delivery Date</div>
                <div>Address</div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '120px 120px 120px auto' }}>
                <div>{shipment.quantity}</div>
                <div>{shipment.ship_quantity}</div>
                <div>{shipment.delivery_date}</div>
                <div>{getFormattedAddress(shipment)}</div>
            </div>
        </>
    )}>
        {shipment.order_delivery_proof?.map(file => <DocumentsDownloader key={file.url} project_id={projectId} image={file.url} name={file.name} />)}
        {shipment.delivery_proof?.map(file => <DocumentsDownloader key={file.url} project_id={projectId} image={file.url} name={file.name} />)}
    </Box>
)
ItemDeliveryDetails.propTypes = {
    projectId: PropTypes.number.isRequired,
    shipment: PropTypes.PropTypes.object.isRequired
}

const SupplierDelivered = () => {
    const { orderDetails } = useRouteLoaderData('OrderManagerDetailsPage')
    const { itemSupplierRating } = orderDetails
    const [vendorRatingVisible, setVendorRatingVisible] = useState(false)
    const [deliveryDetailsVisible, setDeliveryDetailsVisible] = useState(false)
    const vendorRated = !!itemSupplierRating
    const fetcher = useFetcher()
    const ratings = useRef(itemSupplierRating ?? {})
    const deliveredMessage = `Order delivered ${getDateDiffAsMessage(orderDetails.supplier_delivery_date, orderDetails.item_finisher_address.expected_delivery_date)} on ${orderDetails?.supplier_delivery_date} `

    return <div className={styles.container}>
        <Alert message="Product is delivered to Finisher. Finisher is also notified" type="info" />
        <h3>Task for you</h3>
        <Alert message={deliveredMessage} type="info"
            action={
                <Button onClick={() => setDeliveryDetailsVisible(true)}>View Delivery Details</Button>
            } />
        <Alert message='How would you rate your experience with vendor ?' type="info" action={
            <Button onClick={() => setVendorRatingVisible(true)}>{!vendorRated ? 'Rate Vendor' : 'View Rating'}</Button>
        } />
        <Drawer
            title="Delivery Details"
            open={deliveryDetailsVisible}
            onClose={() => setDeliveryDetailsVisible(false)}
            size="large"
        >
            <ItemDeliveryDetails projectId={orderDetails.project_id} shipment={orderDetails.item_finisher_address} />
        </Drawer>
        <Modal
            title="Rate Vendor"
            open={vendorRatingVisible}
            onCancel={() => setVendorRatingVisible(false)}
            cancelText={vendorRated ? 'Close' : false}
            okText={!vendorRated ? 'Submit' : false}
            onOk={() => {
                //Check if ratings are filled
                const parseRatings = ratingSchema.safeParse(ratings.current)
                if (!parseRatings.success) {
                    message.error('Please fill in all ratings for vendor')
                    return
                }

                fetcher.submit({
                    ...ratings.current,
                    action: 'vendorRating'
                }, {
                    method: 'post',
                    encType: 'application/json'
                })
            }}
            destroyOnClose
        >
            <div className="form-fields-wrapper--horizontal">
                <GeneralFieldWrapper label="1. Response Time" horizontal>
                    <Rate onChange={v => ratings.current.response = v} defaultValue={ratings.current.response} disabled={vendorRated} />
                </GeneralFieldWrapper>
                <GeneralFieldWrapper label="2. Delivery" horizontal>
                    <Rate onChange={v => ratings.current.delivery = v} defaultValue={ratings.current.delivery} disabled={vendorRated} />
                </GeneralFieldWrapper>
                <GeneralFieldWrapper label="3. Quality of Product" horizontal>
                    <Rate onChange={v => ratings.current.quality = v} defaultValue={ratings.current.quality} disabled={vendorRated} />
                </GeneralFieldWrapper>
            </div>
        </Modal>
    </div>
}

export const SupplierDeliveredActions = {
    vendorRating: async ({ params, data }) => {
        const { itemId } = params
        try {
            const msg = await addItemSupplierrRating(itemId, data)
            message.success(msg);
            return true
        }
        catch (error) {
            message.error(error.message)
            if (error instanceof ValidationError) {
                return {
                    errors: error.errors
                }
            }
            return false
        }
    }
}

export default SupplierDelivered;