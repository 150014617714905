import { CheckOutlined, CloseOutlined, DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Collapse, Popconfirm, message } from 'antd';
import { debounce } from 'lodash-es';
import { customAlphabet } from 'nanoid/non-secure';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { generatePath, redirect, useActionData, useFetcher, useLoaderData, useNavigate, useSubmit } from 'react-router-dom';
import { createVendor } from '../../api/vendor/create-vendor.js';
import { SmallSection } from '../../components/design';
import { ValidationForm as Form, VendorAddress, VendorContact, VendorDetails } from '../../components/form';
import { Page } from '../../components/page';
import { ValidationError } from '../../library/classes/exceptions/index.js';
import { getCountryById } from '../../library/helpers/country.js';
import { getEntityById } from '../../library/helpers/entity.js';
const { Panel } = Collapse
const nanoid = customAlphabet('1234567890', 4)
const numericNanoid = () => parseInt(nanoid())

const LOCAL_STORAGE_KEY = 'newVendorDetails'
const DEBOUNCE_DURATION = 2000


const getSupportedLanguagesByCountryId = (countryId) => {
	if (!countryId) {
		return []
	}
	const country = getCountryById(countryId)
	const entity = getEntityById(country.default_entity_id)
	//Supported languages
	return entity?.default_language ?? []
}

const VendorCreate = ({ title }) => {
	const [form] = Form.useForm()
	const { vendorDetails, addresses, vendor_contacts, primaryAddressId, invoiceAdddresId, deliveryAddressId, enquiryContactId, orderContactId } = useLoaderData()
	const formData = useRef({ vendorDetails, addresses: addresses.length ? addresses : [], vendor_contacts: vendor_contacts.length ? vendor_contacts : [], primaryAddressId, invoiceAdddresId, deliveryAddressId, enquiryContactId, orderContactId })
	const [contactLanguageOptions, setContactLanguageOptions] = useState(getSupportedLanguagesByCountryId(vendorDetails?.country_id))


	const formValidationStatus = useRef({
		vendorDetails: false,
		addresses: {},
		vendor_contacts: {},
	})
	const actionData = useActionData()
	const { errors = {} } = actionData || {}
	const { addressErrors = [], contactErrors = [], vendorErrors = {} } = errors
	const submit = useSubmit()
	const fetcher = useFetcher()
	const navigate = useNavigate()

	const submitLocally = () => {
		fetcher.submit({
			...formData.current,
			action: 'localUpdate'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	const submitLocallyDelayed = debounce(submitLocally, DEBOUNCE_DURATION)
	const defaultVendorDetails = {
		using_portal: true,
		discount: 0,
		vendor_type: ['Item']
	}

	const handleVendorDetailsSubmit = async (changed, allValues) => {
		//Check if country is changed
		if (changed.country_id) {
			//Supported languages
			setContactLanguageOptions(getSupportedLanguagesByCountryId(changed.country_id))
		}
		formData.current.vendorDetails = { ...allValues }
		// submitLocallyDelayed()
	}
	const handleVendorAddressSubmit = async (id, address) => {
		formData.current.addresses = formData.current.addresses.toSpliced(addresses.findIndex(a => a.id === id), 1, address)
		submitLocallyDelayed()
	}

	const handleVendorContactSubmit = async (id, contact) => {
		formData.current.vendor_contacts = formData.current.vendor_contacts.toSpliced(vendor_contacts.findIndex(a => a.id === id), 1, contact)
		submitLocallyDelayed()
	}

	const getAddressActions = (id) => {
		return ([
			<Popconfirm
				key="delete"
				title="Are you sure to delete this address?"
				onConfirm={() => {
					const index = formData.current.addresses.findIndex(a => a.id === id)
					formData.current.addresses = formData.current.addresses.toSpliced(index, 1)
					submitLocally()
				}}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}
	const getContactAction = (id) => {
		return ([
			<Popconfirm
				key="delete"
				title="Are you sure to delete this contact?"
				onConfirm={() => {
					const index = formData.current.vendor_contacts.findIndex(a => a.id === id)
					formData.current.vendor_contacts = formData.current.vendor_contacts.toSpliced(index, 1)
					submitLocally()
				}}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}

	const handleAddNewAddress = () => {
		formData.current.addresses.push({
			id: numericNanoid(),
			country_id: formData.current.vendorDetails?.country_id
		})
		submitLocally()
	}

	const handleAddNewContact = () => {
		const country_id = form.getFieldValue('country_id')
		const country = getCountryById(country_id)
		formData.current.vendor_contacts.push({
			id: numericNanoid(),
			phone_country_code: country?.phone_number_code,
			mobile_country_code: country?.phone_number_code,
			language_id: formData.current.vendorDetails?.language_id
		})
		submitLocally()
	}
	const handleResetFields = async () => {
		formData.current.vendorDetails = {}
		formData.current.addresses = [{ id: numericNanoid() }]
		formData.current.vendor_contacts = [{ id: numericNanoid() }]

		await form.resetFields()
		submit({
			action: 'resetFields'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}

	const handleFormSubmit = async () => {
		//Check basic validations 
		// const isValid = formValidationStatus.vendorDetails && formValidationStatus.addresses
		// console.log(isValid);
		if (Object.values(formData.current.addresses).length == 0) {
			return message.error('Please add atleast one address')
		}
		if (Object.values(formData.current.vendor_contacts).length == 0) {
			return message.error('Please add atleast one contact')
		}

		submit({
			...formData.current,
			action: 'saveVendor'
		}, {
			method: 'post',
			encType: 'application/json'
		})
	}
	const changeFormsHandler = (formName, { changedFields, forms }) => {
		if (formName !== 'vendorDetails' || !changedFields.find(({ name }) => name.includes('country_id'))) {
			return
		}

		const country_id = forms.vendorDetails.getFieldValue('country_id')
		const language_id = forms.vendorDetails.getFieldValue('language_id')
		const country = getCountryById(country_id)
		for (const fkey in forms) {
			if (!fkey.startsWith('contact')) {
				continue
			}

			if (forms[fkey].getFieldValue('mobile_country_code') == undefined || forms[fkey].getFieldValue('mobile_number') == undefined) {
				forms[fkey].setFieldValue('mobile_country_code', country?.phone_number_code)
			}
			if (forms[fkey].getFieldValue('phone_country_code') == undefined || forms[fkey].getFieldValue('phone_number') == undefined) {
				forms[fkey].setFieldValue('phone_country_code', country?.phone_number_code)
			}
			forms[fkey].setFieldValue('language_id', language_id)
		}
	}

	return (
		<Page className='vendors-detail' title={title} >
			<Form.Provider onFormChange={changeFormsHandler}>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Vendor Details' key="1">
						<VendorDetails
							form={form}
							initialValues={
								Object.keys(vendorDetails).length == 0 ? defaultVendorDetails : vendorDetails
							}
							onValuesChange={(_, data) => handleVendorDetailsSubmit(data)}
							getValidationStatus={isValid => formValidationStatus.current.vendorDetails = isValid}
							errors={vendorErrors}
						/>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Addresses' key="1" >
						{addresses.map((address, index) => (
							<SmallSection
								key={address.id}
								actions={getAddressActions(address.id, address)}
							>
								<VendorAddress
									index={address.id}
									initialValues={address}
									onValuesChange={(_, data) => handleVendorAddressSubmit(address.id, { ...data, id: address.id })}
									getValidationStatus={isValid => formValidationStatus.current.addresses[address.id] = isValid}
									errors={addressErrors?.[index]?.reduce((acc, curr) => Object.assign(acc, curr), {})}
								/>
							</SmallSection>
						))}
						<Button onClick={handleAddNewAddress}>Add New Address</Button>
					</Panel>
				</Collapse>
				<Collapse defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Contacts' key="1">
						{vendor_contacts.map((contact, index) => (
							<SmallSection
								key={contact.id}
								actions={getContactAction(contact.id, contact)}
							// header={(<VendorVendorRolesSelect
							// 	contactId={contact.id}
							// 	onChange={handleContactRoleChange}
							// />)}
							>
								<VendorContact
									index={contact.id}
									initialValues={contact}
									onValuesChange={(_, data) => handleVendorContactSubmit(contact.id, { ...data, id: contact.id })}
									country={vendorDetails.country_id}
									allowedLanguages={contactLanguageOptions}
									getValidationStatus={isValid => formValidationStatus.current.vendor_contacts[contact.id] = isValid}
									errors={contactErrors?.[index]?.reduce((acc, curr) => Object.assign(acc, curr), {})}
								/>
							</SmallSection>
						))}
						<Button onClick={handleAddNewContact}>Add New Contact</Button>
					</Panel>
				</Collapse>
				<div className="actions" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
					<Button onClick={async () => {
						await handleResetFields();
						setTimeout(() => {
							form.resetFields();
						}, 100)
					}} type="primary" icon={<RedoOutlined />}>Reset Fields</Button>
					<Button onClick={handleFormSubmit} type="primary" icon={<CheckOutlined />}>Create</Button>
					<Button onClick={() => {
						if (confirm("Unsaved changes will be lost") == true) {
							navigate(-1)
						}
					}} type="danger" icon={<CloseOutlined />} >Cancel</Button>
				</div>
			</ Form.Provider>
		</Page>
	)
}
VendorCreate.propTypes = {
	title: PropTypes.string
}

VendorCreate.Actions = {
	localUpdate: async ({ data }) => {
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
		return data
	},
	resetFields: async () => {
		localStorage.removeItem(LOCAL_STORAGE_KEY)
		return true

	},

	saveVendor: async ({ data }) => {
		const { vendorDetails, vendor_contacts, addresses } = data
		const formElementsMap = new Map()
		addresses.forEach(({ id }, i) => formElementsMap.set(`address-${i}`, `address-${id}`))
		vendor_contacts.forEach(({ id }, i) => formElementsMap.set(`contact-${i}`, `contact-${id}`))
		try {
			const payload = {
				...vendorDetails,
				vendor_contacts: vendor_contacts.map(c => ({
					...c,
					id: undefined
				})),
				addresses: addresses.map(c => ({
					...c,
					id: undefined
				}))
			}

			const { message: msg, data: { id } } = await createVendor(payload)
			localStorage.removeItem(LOCAL_STORAGE_KEY)
			message.success(msg)
			return redirect(generatePath('/vendors/:id', {
				id
			}))
		}
		catch (error) {
			message.error(error.message)
			if (error instanceof ValidationError) {
				//message.error(error.message)
				let errorFormElement = null
				const formattedErrors = {
					addresses: [],
					vendor_contacts: []
				}

				Object.entries(error.errors).forEach(([k, v]) => {
					//['vendor_contact',0,[]]
					let k_path = k.split('.')
					if (k_path.length === 3) {
						if (k_path[0] === 'addresses') {
							if (formattedErrors.addresses[k_path[1]] == null) formattedErrors.addresses[k_path[1]] = []
							formattedErrors.addresses[k_path[1]].push({
								[k_path[2]]: v
							})
						}
						else if (k_path[0] === 'vendor_contacts') {
							///Send it to vendor_contacts reducer
							if (formattedErrors.vendor_contacts[k_path[1]] == null) formattedErrors.vendor_contacts[k_path[1]] = []
							formattedErrors.vendor_contacts[k_path[1]].push({
								[k_path[2]]: v
							})
						}
					}
					else {
						formattedErrors[k_path] = v
					}
					if (errorFormElement) {
						errorFormElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
					}


				})


				let errorFormElementId = ''
				const { addresses: addressErrors = [], vendor_contacts: contactErrors = [], ...vendorErrors } = formattedErrors
				if (Object.keys(vendorErrors)?.length > 0) {
					errorFormElementId = 'vendorDetails'
				}
				else if (addressErrors.length) {
					const errorIndex = Math.min(...Object.keys(addressErrors))
					errorFormElementId = formElementsMap.get(`address-${errorIndex}`)
				}
				else if (contactErrors.length) {
					const errorIndex = Math.min(...Object.keys(contactErrors))
					errorFormElementId = formElementsMap.get(`contact-${errorIndex}`)
				}
				if (errorFormElementId && document.querySelector(`#${errorFormElementId}`)) {
					document.querySelector(`#${errorFormElementId}`).scrollIntoView({ behavior: 'smooth', block: 'start' })
				}
				return {
					errors: { vendorErrors, addressErrors, contactErrors }
				}
			}

		}
		return false
	}
}

VendorCreate.Loader = async () => {
	let { addresses, vendor_contacts, vendorDetails = {}, primaryAddressId, invoiceAdddresId, deliveryAddressId, enquiryContactId, orderContactId } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}

	//Always maintain atleast one address and one contact
	if (!addresses || addresses.length === 0) {
		addresses = [{ id: numericNanoid() }]
		primaryAddressId = addresses[0].id
	}
	if (!vendor_contacts || vendor_contacts.length === 0) {
		vendor_contacts = [{ id: numericNanoid() }]
		// primaryContactId = vendor_contacts[0].id
	}

	return {
		vendorDetails,
		addresses,
		vendor_contacts,
		primaryAddressId,
		invoiceAdddresId,
		deliveryAddressId,
		enquiryContactId,
		orderContactId
	}
}
export default VendorCreate