import { SearchOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { getLedgers } from '../../api/settings/general-ledger.js';
import { Page } from '../../components/page';
import { useFetch } from '../../hooks';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { LedgersColumns } from '../../library/constants/tableColumns/ledgerColumns.js';
import { searchParamsToObject } from '../../library/helpers/index.js';
function Ledgers(props) {
  const { title } = props;
  const initialData = useLoaderData()
  const navigate = useNavigate()
  const [list, hasMore, isLoading, , {
    setSort,
    setFilters,
    loadMore
  }] = useFetch({ initialData })
  return (
    <Page title={title} header={(
      <>
        <div></div>
        <Space.Compact style={{ width: '100%', maxWidth: '400px' }}>
          <Input.Search placeholder="Search" prefix={<SearchOutlined />} onChange={(e) => setFilters({ 'search': e.target.value })} allowClear />
        </Space.Compact>
        <Button type="primary" onClick={() => { navigate(generateRouteUrl('LedgerCreate')) }} icon={<UserAddOutlined />}>Add Ledger</Button>
      </>
    )} >

      <Table
        columns={LedgersColumns}
        dataSource={list}
        rowKey="id"
        onRow={user => ({
          onClick: () => {
            navigate(generateRouteUrl('LedgerDetails', {
              userId: user.id
            }))
          }
        })}
        isLoading={isLoading}
        onChange={setSort}
        hasMore={hasMore}
        loadMore={loadMore}
        sticky
      />
      <Outlet />
    </Page>
  )
}

Ledgers.Loader = async ({ request }) => {
  const url = new URL(request.url)
  const { filter, page, sort } = searchParamsToObject(url.searchParams)
  const { data, meta } = await getLedgers({ filter, page, ...sort })
  return { data, meta }
}

Ledgers.propTypes = {
  title: PropTypes.string,
}

export default Ledgers
