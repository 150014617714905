export const UserPermissions = {
  DASHBOARD: 'dashboard',
  CREATE_PROJECT: "create_project",
  PROJECT_LIST: "project_list",
  CUSTOMER: "customer",
  COMPANY: "company",
  STUDIO_LIST: "studio_list",
  STUDIO: "studio",
  ORDER_MANAGER_LIST: "order_manager_list",
  COMBI_DEALS: "combi_deals",
  SENT_COMBI_DEALS: "sent_combi_deals",
  ADD_VENDOR: "add_vendor",
  VENDOR_LIST: "vendor_list",
  FINANCE_PROJECT: "finance_project",
  FINANCE_INVOICE: "finance_invoice",
  PURCHASE_BILLS: "purchase_bills",
  EULER: "euler",
  USER: "user",
  SETTINGS: "settings"
}