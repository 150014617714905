import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {
    Button,
    Form, Input, Select
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { PurchaseBillStatusDetails } from '../../../library/constants/purchase-bill-statuses.js';
import {  ExecutiveEntitySelect, VendorSelect } from '../../inputs';
import { useLoaderData } from 'react-router-dom';
import { convertDataTypes } from '../../../library/helpers/index.js';

const PurchaseBillFilterForm = ({ children, data, ...otherProps }) => {
    const { meta } = useLoaderData()
    const [form] = Form.useForm()

    const clearForm = () => {
        try {
            form.setFieldsValue(Object.fromEntries(
                Object.keys(form.getFieldsValue()).map(key => ([key, null]))
            ))
            form.submit()
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <Form initialValues={convertDataTypes(data, {
            vendor_id: parseInt,
            entity_id: parseInt,
        })} form={form} layout='vertical' {...otherProps}>
            <Form.Item name="vendor_id" label="Supplier">
                <VendorSelect currentSelection={meta?.filters?.vendor_id?.id ? { value: meta.filters.vendor_id.id, label: meta.filters.vendor_id.name } : null} />
            </Form.Item>
            <Form.Item name="invoice_number" label="Invoice No.">
                <Input type="text" />
            </Form.Item>
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect allowClear />
            </Form.Item>
            <Form.Item name="status" label="Status" >
                <Select
                    options={Object.entries(PurchaseBillStatusDetails).map(([k, v]) => ({ label: v, value: k }))}
                />
            </Form.Item>
            {children}
            <div className="actions">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Apply</Button>
                <Button onClick={clearForm} icon={<ReloadOutlined />}>Reset</Button>
            </div>
        </Form>
    )
}

PurchaseBillFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default PurchaseBillFilterForm