import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/settings/template';
const ENDPOINT_SINGLE = '/settings/template/:id/:language';
const ENDPOINT_SINGLE_ACTION = '/settings/template/:id/:action';
const ENDPOINT_SINGLE_ACTION_SINGLE = '/settings/template/:id/:action/:actionId';

export const getEmailTemplates = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}
export const getEmailTemplate = (id, language) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id,
        language
    })).then(({ data }) => data)
}
export const updateEmailTemplate = (id, language, data) => (
    axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id,
        language
    }),
        { ...data, language }
    ).then(({ message }) => message)
)
export const getTemplateEmails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'email-history'
    }))
}
export const getEmailDetails = (id, emailId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE_ACTION_SINGLE, {
        id,
        action: 'email-history',
        actionId: emailId,
    })).then(({ data }) => data)
}