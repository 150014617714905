import React from 'react';
import { fetchSmallWebsiteLogo } from '../../../components/design';
import { Server } from '../../constants/server';
import { formatPrice } from '../../utilities/intl';

export const OrderColumns = [
    {
        title: 'Order Id',
        dataIndex: 'id',
        fixed: 'left',
        sorter: true,
    },
    {
        title: 'Item',
        dataIndex: 'product',
        sorter: true,
        fixed: 'left',
        render: (product) => product.name
    },
    {
        title: 'Company',
        dataIndex: ['project', 'customer'],
        render: (customer) => {
            return fetchSmallWebsiteLogo(customer.website, customer.company)
        }
        //sorter: true,
    },
    {
        title: 'Account Manager',
        dataIndex: ['project', 'sales_manager'],
        render: (user) => user?.name
        //sorter: true,
    },
    {
        title: 'Payment Status',
        dataIndex: ['project', 'payment_term'],
        //sorter: true,
        render: (term) => term?.name,
    },
    {
        title: 'Total Price',
        dataIndex: 'approved_vendor_price',
        render: (approved_vendor_price) => formatPrice(approved_vendor_price?.total_price, 'EUR')
        //sorter: true,
    },
    {
        title: 'Country',
        dataIndex: ['project', 'customer'],
        ///sorter: true,
        render: (customer) => {
            return <img src={`${Server.BaseUrl}/${customer?.country?.flag_url}`} alt={customer?.country?.name} title={customer?.country?.name} />
        }
    }
    // {
    //     title: 'Language',
    //     dataIndex: 'language',
    //     //sorter: true,
    //     render: language => language.name
    // },
    // {
    //     title: 'Order Manager',
    //     dataIndex: 'order_manager',
    //     //sorter: true,
    //     render: user => user?.name
    // },
    // {
    //     title: 'Contact',
    //     dataIndex: 'contact',
    //     render: contact => contact?.email
    //     //sorter: true,
    // },

]