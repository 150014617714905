import {
    CheckOutlined,
    CloseOutlined
} from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import React, { useRef } from 'react';
import { Form, redirect, useNavigate, useRouteLoaderData, useSubmit } from 'react-router-dom';
import { recordOfflinePayment } from '../../api/finance/invoice.js';
import { parser } from '../../components/inputs/Currency.jsx';
import { Currency } from '../../components/inputs/index.js';
import Styles from './manual-payment.module.scss';


const ManualPayment = () => {
    const { invoiceDetails } = useRouteLoaderData('InvoiceDetails')
    const navigate = useNavigate()
    const submit = useSubmit();
    const formRef = useRef()
    const currency = invoiceDetails.currency.code

    return (
        <Modal
            title="Record Offline Payment"
            open={true}
            onCancel={() => navigate('../', { replace: true })}
            width='40%'
            forceRender
            destroyOnClose
            footer={<>
                <Button type="primary" icon={<CheckOutlined />} onClick={() => {
                    submit(formRef.current)
                }} >Save</Button>
                <Button type="cancel" icon={<CloseOutlined />} onClick={() => navigate('../', { replace: true })}>Cancel</Button>
            </>}>
            <Form method="post" className={Styles.formWrapper} ref={formRef} replace >
                <div className={Styles.mainWrapper}>
                    <div className={Styles.fieldsWrapper}>
                        <label>Amount Recieved</label>
                        <Currency currency={currency} name="amount" defaultValue={null} />
                    </div>
                </div>
            </Form>
        </Modal>
    )
}

ManualPayment.Action = async ({ params, request }) => {
    const { invoiceId } = params
    const data = Object.fromEntries(await request.formData())

    if (!data.amount) {
        message.error('Amount is required')
        // return redirect('../', {
        //     replace: true
        // })
        return false
    }

    const amount = parser(data.amount, data.currency)

    if (amount <= 0 || isNaN(amount)) {
        message.error('Invalid amount')
        // return redirect('../', {
        //     replace: true
        // })
        return false
    }
    try {
        const msg = await recordOfflinePayment(invoiceId, amount)
        message.success(msg)
        return redirect('../', {
            replace: true
        })
    }
    catch (error) {
        message.error(error.message)
    }
    return false
}


export { ManualPayment };

