import Genders from '../dynamics/genders.json'
import GeneralLedgers from '../dynamics/general-ledgers.json'
import invoiceStatuses from '../dynamics/invoice-statuses.json'
import Languages from '../dynamics/languages.json'
import PaymentConditions from '../dynamics/payment-conditions.json'
import PaymentTerms from '../dynamics/payment-terms.json'
import permission from '../dynamics/permission.json'
import roles_permission from '../dynamics/roles-permission.json'
import Roles from '../dynamics/roles.json'
import userRoles from '../dynamics/user-roles.json'
import VatTypes from '../dynamics/vat-types.json'
import { convertToTitle } from '../helpers/common.js'

const UserRoles = userRoles.map((role) => ({
    id: role.name,
    name: convertToTitle(role.id),
}))

const Permission = permission.map((permission) => (
    {
        id: permission?.name?.name,
        name: convertToTitle(permission?.name?.description),
    }
))

const RolePermission = roles_permission.map((rp) => (
    {
        id: rp.id,
        value: rp.name,
    }
))

const TextTemplateTypes = [
    {
        name: "footer",
        id: 1,
    },
    {
        name: "header",
        id: 2,
    }
]


const findVendorPaymentTermById = (id) => PaymentTerms.find(term => term.id === id)?.name

const ERP_CURRENCY = 'EUR'
const ERP_DEFAULT_LANGUAGE_CODE = 'nl'
const CANCELLATION_FEE_MARGIN = 40

export {
    CANCELLATION_FEE_MARGIN, ERP_CURRENCY,
    ERP_DEFAULT_LANGUAGE_CODE, findVendorPaymentTermById, Genders,
    GeneralLedgers, invoiceStatuses, Languages,
    PaymentConditions,
    PaymentTerms, Permission,
    RolePermission, Roles, TextTemplateTypes,
    UserRoles, VatTypes
}

