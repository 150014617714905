import { Button, Result, message } from 'antd';
import React from 'react';
import { RouterProvider, createBrowserRouter, redirect, useNavigate, useRouteError } from 'react-router-dom';
import { z } from "zod";
import { logout } from './api/auth/login.js';
import { redirectToLogin } from './auth-manager.jsx';
import AuthenticatedPage from './auth-wrapper.jsx';
import { Page } from './components/page';
import { ActionNotFounError, NotFoundError } from './library/classes/exceptions';
import { routesList } from './library/constants/routes.js';
import { searchParamsToObject } from './library/helpers/index.js';
import AppProvider from './providers/app/app-provider.jsx';
import ChangePassword from './routes/login/change-password.jsx';
import { Login, getDefaultPageByRole } from './routes/login/login.jsx';
import ResetPassword from './routes/login/reset-password.jsx';

const formatRoute = (route) => {
  const { id = undefined, lazy, component: Component, title, url: path, shouldRevalidate, additionalProps = undefined } = route
  const props = {
    title,
    ...additionalProps || {},
  };
  const obj = {
    //element: Component ? <Component {...props} /> : <div><Outlet /></div>,
    element: Component ? <Component {...props} /> : undefined,
  }
  if (id) {
    obj.id = id
  }
  if (lazy) {
    obj.lazy = lazy
  }
  if (path) {
    obj.path = path
  }
  else {
    obj.index = true
  }
  if (route.children) {
    obj.children = route.children.map(formatRoute)
  }
  if (Component?.Loader && Component?.Loaders) {
    throw new Error('Route cannot have both `Loader` and `Loaders`', route)
  }

  if (Component?.Loaders) {
    obj.loader = async ({ params, request }) => {
      const url = new URL(request.url)
      let { loader } = searchParamsToObject(url.searchParams) || {}
      if (!loader) {
        loader = 'default'
      }
      if (!Component.Loaders?.[loader]) {
        throw new ActionNotFounError(loader, 'GET', Component.name, route)
      }
      return await Component.Loaders[loader]({ params, request })
    }
  }
  else if (Component?.Loader) {
    obj.loader = Component.Loader
  }

  if (shouldRevalidate) {
    obj.shouldRevalidate = shouldRevalidate
  }

  if (Component?.Action && Component?.Actions) {
    throw new Error('Route cannot have both `Action` and `Actions`', route)
  }

  if (Component?.Actions) {
    obj.action = async ({ params, request }) => {
      let parsedRequestBody = null
      if (request.headers.get('content-type') === 'application/json') {
        parsedRequestBody = await request.json()
      }
      else {
        parsedRequestBody = Object.fromEntries(await request.formData())
      }
      const { action, ...data } = parsedRequestBody
      const { method } = request
      if (!Component.Actions[action]) {
        throw new ActionNotFounError(action, method, Component.name, route)
      }
      return await Component.Actions[action]({ params, method, data })
    }
  }
  else if (Component?.Action) {
    obj.action = Component.Action
  }
  return obj
}


const UserSchema = z.object({
  id: z.number(),
  name: z.string(),
  email: z.string(),
  roles: z.array(z.string()),
  permisions: z.array(z.string())
})


function App() {
  // These user roles can later on come from the profile based on authentication
  // const userRoles = JSON.parse(localStorage.getItem('USER'))?.permisions ?? [];
  // const routes = routesList.filter(
  //   (item) => intersection(item.roles_allowed, userRoles).length > 0
  // );

  const browserRouter = createBrowserRouter([
    {
      path: '/',
      id: 'Root',
      element: <AuthenticatedPage />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          errorElement: <ErrorBoundary />,
          children: [...routesList.map(formatRoute), {
            path: "*",
            element: <PageNotFound />,
          }]
        }
      ],
      loader: async ({ request }) => {
        if (!localStorage.getItem('USER') || !JSON.parse(localStorage.getItem('USER'))) {
          return redirectToLogin(request.url)
        }
        const user = JSON.parse(localStorage.getItem('USER'))
        // check if user object is valid
        try {
          UserSchema.parse(user)
        }
        catch {
          return redirectToLogin(request.url)
        }

        //If on homepath , redirect to default page
        const currentPath = new URL(request.url).pathname
        if ((currentPath === '' || currentPath === '/') && currentPath !== getDefaultPageByRole(user.roles)) {
          return redirect(getDefaultPageByRole(user.roles))
        }

        return {
          user
        }
      },
      action: async ({ request }) => {

        let parsedRequestBody = {}
        if (request.headers.get('content-type') === 'application/json') {
          parsedRequestBody = await request.json()
        }
        else {
          parsedRequestBody = Object.fromEntries(await request.formData())
        }
        const { action/* , ...data */ } = parsedRequestBody
        const { method } = request
        if (action !== 'logout') {
          throw new ActionNotFounError(action, method, 'Root', '/')
        }
        //loggout request
        try {
          const msg = await logout()
          message.success(msg)
          localStorage.removeItem('ACCESS_TOKEN')
          localStorage.removeItem('REFRESH_TOKEN')
          localStorage.removeItem('USER')
          sessionStorage.clear()
          return redirect('/')
        }
        catch (error) {
          message.error(error.message)
        }
        return false
      }
    },
    {
      path: '/login',
      element: <Login />,
      loader: Login.Loader,
      action: Login.Action
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
      action: ResetPassword.Action,
    },
    {
      path: '/reset-password/:token',
      element: <ChangePassword />,
      action: ChangePassword.Action
    }
  ], {
    // future: {
    //   v7_skipActionErrorRevalidation: true, //loaders will not revalidate by default if the action returns or throws a 4xx/5xx Response
    // },
  })

  return (
    <React.Fragment>
      <AppProvider>
        <RouterProvider router={browserRouter} />
      </AppProvider>
    </React.Fragment>
  );
}

function ErrorBoundary() {
  const navigate = useNavigate()
  let error = useRouteError();
  console.error(error);
  if (error instanceof NotFoundError) {
    return <PageNotFound />
  }
  // Uncaught ReferenceError: path is not defined
  return (
    <Page className='error' title="Something Went wrong" >
      <Result
        status="500"
        title="500"
        subTitle={error.message}
        extra={<>
          <Button type="primary" onClick={() => navigate(-1)}>Go Back</Button>
          <Button type="primary" onClick={() => navigate(0)}>Reload</Button>
        </>}
      />
    </Page>
  );
}

function PageNotFound() {
  const navigate = useNavigate()

  return (
    <Page className='error' title="404 Page Not Found" >
      <Result
        status="404"
        title="404"
        subTitle="Page Not Found"
        extra={<Button type="primary" onClick={() => navigate(-1)}>Go Back</Button>}
      />
    </Page>
  );
}

message.config({
  duration: 7,
  maxCount: 5,
})

export default App;
