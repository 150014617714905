import { Card } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './address-view.module.scss';

const Address = ({ address, handleItemClick, selectable = true }) => {
    if (!address) {
        return
    }
    return (
        <Card onClick={() => handleItemClick?.(address)} title={address.street} className={`${styles.card} ${!selectable ? styles.cardNormal : ''}`}>
            <div className={styles.item}>
                <div className={styles.itemLabel}>Street</div>
                <div className={styles.itemValue}>{address.street}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemLabel}>Street No.</div>
                <div className={styles.itemValue}>{address.street_number}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemLabel}>City</div>
                <div className={styles.itemValue}>{address.city}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemLabel}>County</div>
                <div className={styles.itemValue}>{address.county}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemLabel}>Country</div>
                <div className={styles.itemValue}>{address.country?.name}</div>
            </div>
            <div className={styles.item}>
                <div className={styles.itemLabel}>Zipcode</div>
                <div className={styles.itemValue}>{address.zipcode}</div>
            </div>
        </Card>
    )
}

Address.propTypes = {
    address: PropTypes.object.isRequired,
    handleItemClick: PropTypes.func,
    selectable: PropTypes.bool
}

export { Address };
