import {
	Input,
	Select,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ValidationForm as Form } from '../';
import Genders from '../../../library/dynamics/genders';
import { getCountryById } from '../../../library/helpers';
import { LanguageSelect, PhoneInput } from '../../inputs';
import styles from './vendor-contact.module.scss';

const VendorContact = ({ children, index, allowedLanguages, getValidationStatus, country, ...otherProps }) => {
	const [form] = Form.useForm()
	otherProps.initialValues.mobile_country_code = getCountryById(country)?.phone_number_code
	otherProps.initialValues.phone_country_code = getCountryById(country)?.phone_number_code
	// form.({
	// 	phone_country_code: getCountryById(country).phone_number_code,
	// 	mobile_country_code :getCountryById(country).phone_number_code
	// })
	const validationRules = {
		first_name: [
			{
				required: true,
				message: 'First Name is required'
			}
		],
		last_name: [
			{
				required: true,
				message: 'Last Name is required'
			}
		],
		phone_number: [
			{
				required: true,
				message: 'Phone Number is required'
			}
		],
		email: [
			{
				required: true,
				message: 'Email is required'
			},
			{
				type: 'email',
				message: 'Email is not valid'
			},
		],
		gender_id: [
			{
				required: true,
				message: 'Gender is required'
			}
		],
		company_role: [
			{
				required: true,
				message: 'Role is required'
			}
		],
		language_id: [
			{
				required: true,
				message: 'Language is required'
			}
		],
	}

	useEffect(() => {
		if (allowedLanguages?.length === 1) {
			form.setFieldValue('language_id', allowedLanguages[0]);
		}
	}, [allowedLanguages]);

	return (
		<Form form={form} name={`contact-${index}`} layout="vertical" className={styles.FormWrapper} onValidationStatusChange={getValidationStatus} validationRules={validationRules}  {...otherProps}>
			<Form.Item name='role_id'>
				<Select className={styles.RoleButton} options={
					[
						{ id: 'enquiry', name: "Enquiry" },
						{ id: 'order', name: "Order" },
					]
				} fieldNames={{ value: 'id', label: 'name' }} placeholder="Type" />
			</Form.Item>
			<div></div>
			<div></div>
			<div></div>

			<Form.Item name="first_name" label="First Name" >
				<Input placeholder="First Name" onKeyPress={(event) => {
					if (/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}} />
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input placeholder="Last Name" onKeyPress={(event) => {
					if (/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}} />
			</Form.Item>
			<br />
			<Form.Item name="email" label="Email Address" >
				<Input type="email" placeholder="Email" />
			</Form.Item>
			<Form.Item label="Phone Number">
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} placeholder="Telephone No." label='Phone Number' />
			</Form.Item>
			<Form.Item label="Mobile Number">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} placeholder="Mobile No." label='Mobile Number' />
			</Form.Item>
			<Form.Item name='gender_id' label="Gender" >
				<Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} placeholder="Gender" />
			</Form.Item>

			<Form.Item name='language_id' label="Language" >
				<LanguageSelect options={allowedLanguages} />
			</Form.Item>
			{children}
		</Form>
	)
}
//suffixIcon={<Icon component={() => (<img src="/icons/dropdown.svg" />)} styles={{pointerEvents: 'none'}} />}
VendorContact.propTypes = {
	children: PropTypes.node,
	index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	getValidationStatus: PropTypes.func,
	country: PropTypes.number,
	allowedLanguages: PropTypes.array,
}

export default VendorContact